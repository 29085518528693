import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    isFormSubmitted: true
  }

  leavingPage(event) {
    if (!this.isFormSubmittedValue) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm("Are you sure you want to exit this page?")) { 
          event.preventDefault()
        }
      } else {
        event.returnValue = "Are you sure you want to exit this page?";
        return event.returnValue;
      }
    } 
  }

  allowFormSubmission(event) {
    this.isFormSubmittedValue = true
  }

  notAllowedToLeavingPage(event) {
    this.isFormSubmittedValue = false
  }

  closePage(event) {
		if (!this.isFormSubmittedValue) {
      let confirmed = confirm("Are you sure you want to close this page?")
      if (!confirmed) {
        event.preventDefault();
      } else {
        this.redirect_page();
      }
		} else {
      this.redirect_page();
    }
  }

  redirect_page() {
    this.isFormSubmittedValue = true
    let referrer = document.referrer

    if (referrer == '') {
      window.location = '/';
    } else {
      window.close();
    }
  }
}