import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

import Dropzone from "dropzone"

export default class extends Controller {
  static targets = ["dropzoneContainer", "uploadedAsset"]

  static values = {
    url: String,
    accountId: Number,
    assetableId: Number,
    assetableType: String,
    maxFileSize: 3,
    acceptedFiles: 'image/*,application/pdf',
  }

  connect() {
    Dropzone.autoDiscover = false;
    this.dropzone = this.createDropZone()

    this.attachEventListeners()
  }

  createDropZone() {
    return new Dropzone(this.dropzoneContainerTarget, {
      url: this.urlValue,
      method: 'post',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Account-Id': this.accountIdValue
      },
      uploadMultiple: true,
      disablePreviews: true,
      autoQueue: true,
    })
  }

  attachEventListeners() {
    this.dropzone.on("sendingmultiple", this.onSendingMultiple.bind(this));
    this.dropzone.on("successmultiple", this.onSuccessMultiple.bind(this));
  }

  onSendingMultiple(data, xhr, formData) {
    xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html')

    formData.append('assetable_id', this.assetableIdValue)
    formData.append('assetable_type', this.assetableTypeValue)
  }

  onSuccessMultiple(files, response) {
    Turbo.renderStreamMessage(response)
  }

  clearAttachedFiles() {
    this.uploadedAssetTargets.forEach((el) => {
      fetch(this.urlValue + '/' + el.dataset.assetId, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Account-Id': this.accountIdValue
        }
      })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html))
    })
  }
}