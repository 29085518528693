import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["subscriberSummary", "aggregateSummary", "accordion", "button", "subscriberSummaryitem", "deductibleStatus"]
  
  toggleSectionVisibility(e){
    if (e.detail.status == 'incomplete') {
      this.#showIncompleteIcon(e.detail.subscriberId)
      if (this.hasButtonTarget) this.buttonTarget.classList.add('hidden')
      return 
    }

    if (e.detail.status == 'complete') {
      this.#showCompleteIcon(e.detail.subscriberId)
      if (e.detail.showNextSection) this.#showNextSection(e.detail.subscriberId)
      if (this.hasButtonTarget) this.#showSubmitButton(e.detail.subscriberId)
      return 
    }
  }

  clearHiddenInputs(event) {
    let wrapper = document.querySelectorAll("div[data-subscriber-summary-form-target]")
    wrapper.forEach((el) => {
      let targetName = $(el).attr('data-subscriber-summary-form-target')
      let idWrapper = targetName.includes("IdInputWrapper")
      let verificationIdWrapper = targetName.includes("VerificationIdInputWrapper")
      let verificationRequestIdWrapper = targetName.includes("VerificationRequestIdInputWrapper")
      let subscriberIdWrapper = targetName.includes("SubscriberIdInputWrapper")
      let subscriberSummaryIdWrapper = targetName.includes("SubscriberSummaryIdInputWrapper")
      let sectionWrapper = targetName.includes("Section")
      let otherLimitationsSection = targetName.includes("otherLimitationsSection")
      let attachedFilesFileUploadWrapper = targetName.includes("attachedFilesFileUploadWrapper")

      if (el.classList.contains("hidden") && !idWrapper && !verificationIdWrapper && !verificationRequestIdWrapper && !subscriberIdWrapper && !subscriberSummaryIdWrapper && !sectionWrapper) {
        let inputWrapperEl = $(el)
        let inputEl = $(inputWrapperEl.children()[1])

        // if radio
        let selectedRadioButtons = el.querySelectorAll("input[type=radio]:checked")
        if (selectedRadioButtons) selectedRadioButtons.forEach((item)=>{ item.checked = false })

        // if checkbox
        let selectedCheckboxes = el.querySelectorAll("input[type=checkbox]:checked")
        if (selectedCheckboxes) selectedCheckboxes.forEach((item)=>{$(item).prop('checked', false)})

        inputEl.val('')
      }

      // if checkbox element containing hidden class
      let options = el.querySelectorAll("span.checkbox")
      options.forEach(option => {
        if(option.classList.contains('hidden')){
          option.querySelector('input').checked = false
        }
      })

      // if other limitations fields
      if (el.classList.contains("hidden") && otherLimitationsSection) {
        let nestedFields = el.querySelectorAll(".nested-fields")
        if (nestedFields){
          nestedFields.forEach((item)=>{
            $(item).find('input').each((i, input) => { $(input).val(true) });
          })
        }
      }

      // if attached files
      if (el.classList.contains("hidden") && attachedFilesFileUploadWrapper) {
        const controllerEl = el.querySelector('[data-controller="files-upload"]')
        const uploadFilesController = this.application.getControllerForElementAndIdentifier(controllerEl, 'files-upload')
        
        uploadFilesController.clearAttachedFiles();
      }
    });

    let wrapperAggregate = document.querySelectorAll("div[data-aggregate-summary-target]")
    wrapperAggregate.forEach((el) => {
      if (el.classList.contains("hidden")) {
        let inputWrapperEl = $(el)
        let inputEl = $(inputWrapperEl.children()[1])
        let selectedCheckboxes = el.querySelectorAll("input[type=checkbox]:checked")
        if (selectedCheckboxes) selectedCheckboxes.forEach((item)=>{$(item).prop('checked', false)})
        inputEl.val('')
      }
    });
  }

  onSaveSuccess(event) {
    let resourceFrames = document.querySelectorAll(".subscriber-summary-resource");

    resourceFrames.forEach((el) => {
      el.reload()
    });

    let swalHtml = document.querySelector("#swal2-html-container");
    if (swalHtml != null) {
      let iframe = swalHtml.querySelector('iframe')
      if (iframe != null) {
        let src = iframe.src;
        iframe.src = src;
      }
    }
  }

  deductibleStatus(e) {
    if (this.hasAggregateSummaryTarget) {
      this.deductibleStatusTarget.setAttribute('data-aggregate-summary-deductible-status-value', e.detail.deductible_status)
      this.#dispatchAggregateSummaryEvent({ name: 'showHideDeductible' })
    }
  }

  #showCompleteIcon(subscriberId) {
    this.#dispatchAccordionEvent({ name: 'updateIcon', detail: { sectionId: `subscriber_summary_${subscriberId}`, iconName: "complete" }})
  }

  #showIncompleteIcon(subscriberId) {
    this.#dispatchAccordionEvent({ name: 'updateIcon', detail: { sectionId: `subscriber_summary_${subscriberId}`, iconName: "incomplete" }})
  }

  #showNextSection(subscriberId) {
    this.#hideSubscriberSummaryForm(subscriberId)
    this.#showSubscriberSummaryFormAfter(subscriberId)  
  }

  #showSubmitButton(subscriberId) {
    if(this.subscriberSummaryitemTargets.length >= 2 || subscriberId == 'aggregate'){
      this.buttonTarget.classList.remove('hidden')
    }
  }

  #dispatchAccordionEvent({ name, detail}) {
    let event = new CustomEvent(name, { detail }  )
    this.accordionTarget.dispatchEvent(event)
  }

  #dispatchAggregateSummaryEvent({ name }) {
    let event = new CustomEvent(name)
    this.aggregateSummaryTarget.dispatchEvent(event)
  }

  #hideSubscriberSummaryForm(subscriberId) {
    this.#dispatchAccordionEvent({name: 'hideSection', detail: { sectionId: `subscriber_summary_${subscriberId}` }})
  }

  #showSubscriberSummaryFormAfter(subscriberId) {
    this.#dispatchAccordionEvent({name: 'showSectionAfter', detail: { sectionId: `subscriber_summary_${subscriberId}` }})
  }
}