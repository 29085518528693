import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

  connect() {
    $(this.element).find('input').each((i, el) => {
      $(el).prop("disabled", true);
    });

    $(this.element).find('select').each((i, el) => {
      $(el).prop("disabled", true);
    });

    $(this.element).find('textarea').each((i, el) => {
      $(el).prop("disabled", true);
    });
  }
}
