import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showIf: ["true"],
    showIfNot: 'true',
  };

  static targets = [
    "container",
    "checkbox",
    "childContainer",
    "zeroValue",
    "nilValue",
  ];

  connect() {}

  handleChange(e) {
    let result = e.target.value;

    if (this.showIfValue.includes(result)) {
      return this.containerTarget.classList.remove("hidden");
    } else if (this.showIfNotValue != 'true' && !this.showIfNotValue.includes(result)) {
      return this.containerTarget.classList.remove("hidden");
    } else {
      let nestedFields = this.element.querySelectorAll(
        ".nested-fields .fa-trash"
      );
      nestedFields.forEach((el) => {
        el.click();
      });
      this.setZeroValue();
      this.setNilValue();
      this.resetInput(this.containerTarget)
      return this.containerTarget.classList.add("hidden");
    }
  }

  handleChangeChild(e) {
    let result = e.target.value;
    if (result == "true") {
      return this.childContainerTarget.classList.remove("hidden");
    } else {
      this.setZeroValue();
      return this.childContainerTarget.classList.add("hidden");
    }
  }

  handleChangeResponsibilityUnit(e) {
    let result = e.target.value;

    this.childContainerTarget.querySelectorAll('select')[0].value = ''
    if(result == 'copay'){
      this.childContainerTarget.querySelectorAll('select')[0].value = '$'
    } else if(result == 'coinsurance'){
      this.childContainerTarget.querySelectorAll('select')[0].value = '%'
    }

    if (result == "other") {
      return this.childContainerTarget.classList.remove("hidden");
    } else {
      return this.childContainerTarget.classList.add("hidden");
    }
  }

  handleCheckCall(e) {
    let hide = true;
    this.checkboxTargets.filter((checkbox) => {
      if (checkbox.checked && checkbox.value === "call") {
        hide = false;
      }
    });
    if (hide === false) {
      return this.containerTarget.classList.remove("hidden");
    } else {
      return this.containerTarget.classList.add("hidden");
    }
  }

  setZeroValue() {
    this.zeroValueTargets.forEach((target) => {
      target.value = 0;
    });
  }

  setNilValue() {
    this.nilValueTargets.forEach((target) => {
      target.value = null;
    });
  }

  resetInput(container) {
    container.querySelectorAll('input').forEach((input) => {
      input.value = ''
    })
    container.querySelectorAll('select').forEach((select) => {
      select.value = ''
    })
    container.querySelectorAll('textarea').forEach((textarea) => {
      textarea.value = ''
    })
  }
}
