import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = {
    title: 'Are you sure?',
    body: "You won't be able to revert this!",
    icon: 'warning',
    confirmButtonText: 'Yes',
    confirmButtonColor: '#F87171',
    showCancelButton: true,
    reverseButtons: false,
    width: 500,
    html: String,
    contentLoaded: false
  }

  CONFIRMATION_OPTIONS = {
    title: this.titleValue,
    text: this.bodyValue,
    icon: this.iconValue,
    width: this.widthValue,
    html: this.htmlValue,
    showCancelButton: this.showCancelButtonValue,
    confirmButtonColor: this.confirmButtonColorValue,
    confirmButtonText: this.confirmButtonTextValue,
    reverseButtons: true,
    didOpen: () => {
      if (this.contentLoadedValue) return

      Swal.showLoading()
      const iframe = Swal.getHtmlContainer().querySelector('iframe')
      if (iframe) {
        iframe.addEventListener('load', () => {
          Swal.hideLoading()
        })
      }
    }
  };

  display(event) {
    event.preventDefault()
    Swal.fire(this.CONFIRMATION_OPTIONS).then((result) => {
      if (result.isConfirmed) {
        Rails.fire(this.element, 'submit')
      }
    })
  }

  displayReport(event) {
    event.preventDefault()
    Rails.fire(this.element, 'submit');
    let submitTo = document.getElementById('verification_request_submit_to');
    submitTo.value = event.currentTarget.textContent
    Swal.fire(this.CONFIRMATION_OPTIONS).then((result) => {
      if (result.isConfirmed) {
        Rails.fire(this.element, 'submit');
      }
      submitTo.value = ''
    });
  }
}