import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input"]
  static values = {
    delay: Number,
    formState: {}
  };

  initialize() {
    this.save = this.save.bind(this);
  }

  connect() {
    this.updateFormState()
    if (this.delayValue > 0) {
      this.save = _.debounce(this.save, this.delayValue);
    }
  }

  updateFormState() {
    this.formStateValue = this.getFormState()
  }

  getFormState() {
    return this.inputTargets.reduce((prev,curr)=> {
      prev[curr.name] = curr.value

      return prev
    },{})
  }

  statesMatch() {
    let currentFormState = this.getFormState()
    let formState = this.formStateValue

    return Object.keys(this.formStateValue).reduce((prev,curr)=>{
      if (!prev) return false

      return currentFormState[curr] == formState[curr]
    },true)
  }

  save() {
    if (!window._rails_loaded) return;

    if (this.statesMatch()) return;

    // remove all error messages on submit (useful with turbo_streams)
    document
      .querySelectorAll(".alert-danger")
      .forEach((el) => el.classList.add("d-none"));
    document
      .querySelectorAll(".is-invalid")
      .forEach((el) => el.classList.remove("is-invalid"));
    document.querySelectorAll(".invalid-feedback").forEach((e) => e.remove());

    this.updateFormState()

    Rails.fire(this.element, "submit");
  }

  onSaveSuccess(event) {
    let resourceFrames = document.querySelectorAll(".subscriber-summary-resource");

    resourceFrames.forEach((el) => {
      el.reload()
    });
  }
}
