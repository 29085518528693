import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "background", "commitButton"]

  toggle = () => this.isVisible() ? this.show() : this.hide()

  isVisible = () => this.dropdownTarget.classList.contains("hidden")

  updateSelection = (e) => {
    this.commitButtonTarget.innerText = e.target.innerText
    this.hide()
  }

  show = () => {
    this.dropdownTarget.classList.remove("hidden")
    this.backgroundTarget.classList.remove("hidden")
  }

  hide = () => {
    this.dropdownTarget.classList.add("hidden")
    this.backgroundTarget.classList.add("hidden")
  }
}
