import { Controller } from "@hotwired/stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["backwardButton", "forwardButton", "audioElement"];

  initialize() {
    this.setupPlayer = this.setupPlayer.bind(this);
  }

  connect() {
    this.setupPlayer();
    document.addEventListener("turbo:load", this.setupPlayer);
    this.backwardButtonTarget.addEventListener("click", this.rewind.bind(this));
    this.forwardButtonTarget.addEventListener("click", this.forward.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.setupPlayer);
    this.backwardButtonTarget.removeEventListener("click", this.rewind.bind(this));
    this.forwardButtonTarget.removeEventListener("click", this.forward.bind(this));
    this.player?.destroy();
  }

  setupPlayer() {
    if (this.player) {
      this.player.destroy();
    }
    this.player = new Plyr(this.audioElementTarget, {});
  }

  rewind(e) {
    e.preventDefault();
    this.player.rewind(10);
  }

  forward(e) {
    e.preventDefault();
    this.player.forward(10);
  }

  goToTime({ detail: { seconds }}) {
    if (!this.player) return;
    if (!seconds || isNaN(seconds)) return;
    
    this.player.currentTime = parseFloat(seconds);
  }
}
