import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["section", "title", "body"]

  connect() {
    document.querySelector('.accordion-section-body').classList.remove('hidden')
    this.#toggleCaret(this.element.querySelector('.caret'))
    this.setMeasurements()
  }

  toggleVisibility = (e) => {
    let thisBody = e.currentTarget.nextElementSibling

    if (!thisBody.classList.contains("hidden")) return

    this.element.querySelectorAll('.accordion-section-body').forEach(item=>item.classList.add('hidden'))
    thisBody.classList.remove('hidden')

    this.#toggleCaret(e.currentTarget.querySelector('.caret'))
    this.showHideCallInfo(e)
  }

  showHideCallInfo(e) {
    this.sectionTargets.forEach((target) => {
      let callInfo = document.querySelector(`[id=${target.id}_call_information]`)
      if(callInfo != null) {
        let body = target.querySelector('.accordion-section-body')
        if(body.classList.contains("hidden")) {
          document.querySelector(`[id=${target.id}_call_information]`).classList.add('hidden')
        }else{
          let callBtn = document.querySelector(`[id=${target.id}_call_btn]`)
          if (callBtn.classList.contains("hidden")) {
            document.querySelector([`[id=${target.id}_call_information]`]).classList.remove('hidden')
          }
        }
      }
    });

    if (e.currentTarget.textContent == 'AGGREGATE SUMMARY') {
      document.querySelectorAll('.call-informations').forEach(item=>item.classList.add('hidden'))
    }
  }

  setMeasurements() {
    // A timeout is here in the case that a title is larger title than one line and the controller connects before it renders
    setTimeout(()=>{
      this.#setHeaderHeight(this.titleTargets.reduce((total, current) => total += current.offsetHeight,0))
      this.#setNumberOfSections(this.titleTargets.length)
      this.#setBodyHeight(`calc(${this.element.offsetHeight}px - ${this.headerHeight}px)`)
    },100)
  }

  updateIcon(e) {
    let section = this.sectionTargets.find(sectionTarget=> sectionTarget.id == e.detail.sectionId)
    let el = section.querySelector(`.title`)
    
    let baseClasses = 'flex-shrink-0 w-[26px] h-[26px] flex items-center justify-center rounded-full text-white fas icon '

    let icons = {
      incomplete: baseClasses + "bg-gray-400 fa-solid fa-clock",
      complete: baseClasses + "bg-green-600 fa-check",
    }
    
    el.querySelector(".icon").setAttribute('class', icons[e.detail.iconName])
  }

  hideSection(e) {
    let sectionIds = this.sectionTargets.map(result => result.id)
    if (sectionIds.length === 1 || (sectionIds[sectionIds.length - 1] == e.detail.sectionId)) return

    let index = sectionIds.indexOf(e.detail.sectionId);
    let hideSectionId = sectionIds[index]
    let hideSection = this.sectionTargets.find(sectionTarget=> sectionTarget.id == hideSectionId)
    let thisBody = hideSection.querySelector('.accordion-section-body')
    thisBody.classList.add('hidden')
    this.#hideAllCarets()
    this.showHideCallInfo(e)
  }

  showSectionAfter(e) {
    let sectionIds = this.sectionTargets.map(result => result.id)
    let index = sectionIds.indexOf(e.detail.sectionId);
    let nextSectionId = sectionIds[index + 1]
    if (nextSectionId !== undefined){
      let nextsection = this.sectionTargets.find(sectionTarget=> sectionTarget.id == nextSectionId)
      let thisBody = nextsection.querySelector('.accordion-section-body')
      thisBody.classList.remove('hidden')
      this.#toggleCaret(nextsection.querySelector('.caret'))
    }
    this.showHideCallInfo(e)
  }

  #toggleCaret(caret){
    this.#hideAllCarets()

    caret.classList.remove('fa-caret-left')
    caret.classList.add('fa-caret-down')
  }

  #hideAllCarets(){
    this.element.querySelectorAll('.caret').forEach(item=> {
      item.classList.remove('fa-caret-down')
      item.classList.add('fa-caret-left')
    })
  }

  #setHeaderHeight(headerHeight) {
    this.headerHeight = headerHeight
  }

  #setNumberOfSections(numberOfSections) {
    this.numberOfSections = numberOfSections
  }

  #setBodyHeight(bodyHeight) {
    this.bodyTargets.forEach(thisBody=> thisBody.style.height = bodyHeight)
  }
}
