export default class FormRules {
  constructor({ expectedNetworkStatus, isMedicare, isDeductibleMet, formState, isUhc }) {
    this.expectedNetworkStatus = expectedNetworkStatus
    this.conflictingNetworkStatus = ["in_network", "out_of_network"].find((item)=> item != expectedNetworkStatus)
    this.isMedicare = isMedicare
    this.isUhc = isUhc
    this.isDeductibleMet = isDeductibleMet
    this.formState = formState
    this.result = {}
    this.setResult()
    this.setSectionStatus()
  }

  setResult = () => {
    this.result = {
      directionsSection: this.directionsSection(),
      directionsProviderIdInputWrapper: this.directionsProviderIdInputWrapper(),
      directionsServiceTypeInputWrapper: this.directionsServiceTypeInputWrapper(),
      directionsPlaceOfServiceInputWrapper: this.directionsPlaceOfServiceInputWrapper(),

      callSummarySection: this.callSummarySection(),
      callSummaryIsCallRequiredInputWrapper: this.callSummaryIsCallRequiredInputWrapper(),

      networkStatusSummarySection: this.networkStatusSummarySection(),
      networkStatusSummaryNetworkStatusInputWrapper: this.networkStatusSummaryNetworkStatusInputWrapper(),
      networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper: this.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper(),
      networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapper: this.networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapper(),

      coverageStatusSummarySection: this.coverageStatusSummarySection(),
      coverageStatusSummaryIsVerifiableInputWrapper: this.coverageStatusSummaryIsVerifiableInputWrapper(),
      coverageStatusSummaryNotVerifiableReasonIdsInputWrapper: this.coverageStatusSummaryNotVerifiableReasonIdsInputWrapper(),
      coverageStatusSummaryOtherNotVerifiableReasonInputWrapper: this.coverageStatusSummaryOtherNotVerifiableReasonInputWrapper(),
      coverageStatusSummaryIsPlanActiveInputWrapper: this.coverageStatusSummaryIsPlanActiveInputWrapper(),
      coverageStatusSummaryIsBenefitCoveredInputWrapper: this.coverageStatusSummaryIsBenefitCoveredInputWrapper(),
      coverageStatusSummaryTimePeriodUnitInputWrapper: this.coverageStatusSummaryTimePeriodUnitInputWrapper(),
      coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper: this.coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper(),
      coverageStatusSummaryTimePeriodStartDateInputWrapper: this.coverageStatusSummaryTimePeriodStartDateInputWrapper(),
      coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper: this.coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper(),
      coverageStatusSummaryTimePeriodEndDateInputWrapper: this.coverageStatusSummaryTimePeriodEndDateInputWrapper(),
      coverageStatusSummaryPolicyTypeInputWrapper: this.coverageStatusSummaryPolicyTypeInputWrapper(),
      coverageStatusSummaryOtherPolicyTypeInputWrapper: this.coverageStatusSummaryOtherPolicyTypeInputWrapper(),
      coverageStatusSummaryPolicyNameInputWrapper: this.coverageStatusSummaryPolicyNameInputWrapper(),
      coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper: this.coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper(),
      coverageStatusSummaryPolicyKindInputWrapper: this.coverageStatusSummaryPolicyKindInputWrapper(),
      coverageStatusSummaryOtherPolicyKindInputWrapper: this.coverageStatusSummaryOtherPolicyKindInputWrapper(),

      individualDeductibleSummarySection: this.individualDeductibleSummarySection(),
      individualDeductibleSummaryIsPresentInputWrapper: this.individualDeductibleSummaryIsPresentInputWrapper(),
      individualDeductibleSummaryIsMetInputWrapper: this.individualDeductibleSummaryIsMetInputWrapper(),
      individualDeductibleSummaryTotalAmountInputWrapper: this.individualDeductibleSummaryTotalAmountInputWrapper(),
      individualDeductibleSummarySpentAmountInputWrapper: this.individualDeductibleSummarySpentAmountInputWrapper(),
      individualDeductibleSummaryRemainingAmountInputWrapper: this.individualDeductibleSummaryRemainingAmountInputWrapper(),

      familyDeductibleSummarySection: this.familyDeductibleSummarySection(),
      familyDeductibleSummaryIsPresentInputWrapper: this.familyDeductibleSummaryIsPresentInputWrapper(),
      familyDeductibleSummaryIsMetInputWrapper: this.familyDeductibleSummaryIsMetInputWrapper(),
      familyDeductibleSummaryTotalAmountInputWrapper: this.familyDeductibleSummaryTotalAmountInputWrapper(),
      familyDeductibleSummarySpentAmountInputWrapper: this.familyDeductibleSummarySpentAmountInputWrapper(),
      familyDeductibleSummaryRemainingAmountInputWrapper: this.familyDeductibleSummaryRemainingAmountInputWrapper(),

      upcomingServiceTypeVisitDeductibleApplicableStatementSummarySection: this.upcomingServiceTypeVisitDeductibleApplicableStatementSummarySection(),
      upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper: this.upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper(),

      upcomingVisitDeductibleApplicableStatementSummarySection: this.upcomingVisitDeductibleApplicableStatementSummarySection(),
      upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper: this.upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper(),

      individualOutOfPocketMaximumSummarySection: this.individualOutOfPocketMaximumSummarySection(),
      individualOutOfPocketMaximumSummaryIsPresentInputWrapper: this.individualOutOfPocketMaximumSummaryIsPresentInputWrapper(),
      individualOutOfPocketMaximumSummaryIsMetInputWrapper: this.individualOutOfPocketMaximumSummaryIsMetInputWrapper(),
      individualOutOfPocketMaximumSummaryRemainingAmountInputWrapper: this.individualOutOfPocketMaximumSummaryRemainingAmountInputWrapper(),
      individualOutOfPocketMaximumSummarySpentAmountInputWrapper: this.individualOutOfPocketMaximumSummarySpentAmountInputWrapper(),
      individualOutOfPocketMaximumSummaryTotalAmountInputWrapper: this.individualOutOfPocketMaximumSummaryTotalAmountInputWrapper(),

      familyOutOfPocketMaximumSummarySection: this.familyOutOfPocketMaximumSummarySection(),
      familyOutOfPocketMaximumSummaryIsPresentInputWrapper: this.familyOutOfPocketMaximumSummaryIsPresentInputWrapper(),
      familyOutOfPocketMaximumSummaryIsMetInputWrapper: this.familyOutOfPocketMaximumSummaryIsMetInputWrapper(),
      familyOutOfPocketMaximumSummaryTotalAmountInputWrapper: this.familyOutOfPocketMaximumSummaryTotalAmountInputWrapper(),
      familyOutOfPocketMaximumSummarySpentAmountInputWrapper: this.familyOutOfPocketMaximumSummarySpentAmountInputWrapper(),
      familyOutOfPocketMaximumSummaryRemainingAmountInputWrapper: this.familyOutOfPocketMaximumSummaryRemainingAmountInputWrapper(),


      upcomingVisitOutOfPocketMaximumApplicableStatementSummarySection: this.upcomingVisitOutOfPocketMaximumApplicableStatementSummarySection(),
      upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper: this.upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper(),

      medicareSummarySection: this.medicareSummarySection(),
      medicareSummaryTotalBenefitCapInputWrapper: this.medicareSummaryTotalBenefitCapInputWrapper(),
      medicareSummaryRemainingBenefitCapInputWrapper: this.medicareSummaryRemainingBenefitCapInputWrapper(),
      medicareSummaryPartInputWrapper: this.medicareSummaryPartInputWrapper(),
      medicareSummaryIsQmbInputWrapper: this.medicareSummaryIsQmbInputWrapper(),
      medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper: this.medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper(),
      medicareSummaryCurrentEpisodeOfHomeHealthDateInputWrapper: this.medicareSummaryCurrentEpisodeOfHomeHealthDateInputWrapper(),

      visitLimitSummarySection: this.visitLimitSummarySection(),
      visitLimitSummaryHasVisitLimitInputWrapper: this.visitLimitSummaryHasVisitLimitInputWrapper(),
      visitLimitSummaryNumberAllowedInputWrapper: this.visitLimitSummaryNumberAllowedInputWrapper(),
      visitLimitSummaryNumberUsedInputWrapper: this.visitLimitSummaryNumberUsedInputWrapper(),
      visitLimitSummaryNumberRemainingInputWrapper: this.visitLimitSummaryNumberRemainingInputWrapper(),
      visitLimitSummaryUnitInputWrapper: this.visitLimitSummaryUnitInputWrapper(),
      visitLimitSummaryOtherUnitInputWrapper: this.visitLimitSummaryOtherUnitInputWrapper(),
      visitLimitSummaryMaxKindInputWrapper: this.visitLimitSummaryMaxKindInputWrapper(),
      visitLimitSummaryIsVisitLimitCombinedInputWrapper: this.visitLimitSummaryIsVisitLimitCombinedInputWrapper(),
      visitLimitSummaryCombinedVisitKindIdsInputWrapper: this.visitLimitSummaryCombinedVisitKindIdsInputWrapper(),
      visitLimitSummaryOtherCombinedVisitLimitInputWrapper: this.visitLimitSummaryOtherCombinedVisitLimitInputWrapper(),
      visitLimitSummaryHasSameDayModalityLimitInputWrapper: this.visitLimitSummaryHasSameDayModalityLimitInputWrapper(),
      visitLimitSummarySameDayModalityLimitDetailsInputWrapper: this.visitLimitSummarySameDayModalityLimitDetailsInputWrapper(),
      visitLimitSummaryAdditionalNotesInputWrapper: this.visitLimitSummaryAdditionalNotesInputWrapper(),

      dollarLimitSection: this.dollarLimitSection(),
      dollarLimitHasLimitInputWrapper: this.dollarLimitHasLimitInputWrapper(),
      dollarLimitNumberAllowedInputWrapper: this.dollarLimitNumberAllowedInputWrapper(),
      dollarLimitNumberUsedInputWrapper: this.dollarLimitNumberUsedInputWrapper(),
      dollarLimitNumberRemainingInputWrapper: this.dollarLimitNumberRemainingInputWrapper(),

      authorizationSummarySection: this.authorizationSummarySection(),
      authorizationSummaryIsRequiredInputWrapper: this.authorizationSummaryIsRequiredInputWrapper(),
      authorizationSummaryWhenIsItRequiredInputWrapper: this.authorizationSummaryWhenIsItRequiredInputWrapper(),
      authorizationSummaryHowIsItSubmittedInputWrapper: this.authorizationSummaryHowIsItSubmittedInputWrapper(),
      authorizationSummaryAdditionalNotesInputWrapper: this.authorizationSummaryAdditionalNotesInputWrapper(),

      medicalNecessityReviewSummarySection: this.medicalNecessityReviewSummarySection(),
      medicalNecessityReviewSummaryIsRequiredInputWrapper: this.medicalNecessityReviewSummaryIsRequiredInputWrapper(),
      medicalNecessityReviewSummaryWhenIsItRequiredInputWrapper: this.medicalNecessityReviewSummaryWhenIsItRequiredInputWrapper(),
      medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper: this.medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper(),
      medicalNecessityReviewSummaryAdditionalNotesInputWrapper: this.medicalNecessityReviewSummaryAdditionalNotesInputWrapper(),
      
      clinicalSubmissionSummarySection: this.clinicalSubmissionSummarySection(),
      clinicalSubmissionSummaryIsRequiredInputWrapper: this.clinicalSubmissionSummaryIsRequiredInputWrapper(),
      clinicalSubmissionSummaryWhenIsItRequiredInputWrapper: this.clinicalSubmissionSummaryWhenIsItRequiredInputWrapper(),
      clinicalSubmissionSummaryHowIsItSubmittedInputWrapper: this.clinicalSubmissionSummaryHowIsItSubmittedInputWrapper(),

      referralSummarySection: this.referralSummarySection(),
      referralSummaryIsRequiredInputWrapper: this.referralSummaryIsRequiredInputWrapper(),
      referralSummaryWhenIsItRequiredInputWrapper: this.referralSummaryWhenIsItRequiredInputWrapper(),
      referralSummaryAdditionalNotesInputWrapper: this.referralSummaryAdditionalNotesInputWrapper(),

      prescriptionSummarySection: this.prescriptionSummarySection(),
      prescriptionSummaryIsRequiredInputWrapper: this.prescriptionSummaryIsRequiredInputWrapper(),
      prescriptionSummaryWhenIsItRequiredInputWrapper: this.prescriptionSummaryWhenIsItRequiredInputWrapper(),
      prescriptionSummaryHowIsItSubmittedInputWrapper: this.prescriptionSummaryHowIsItSubmittedInputWrapper(),
      prescriptionSummaryAdditionalNotesInputWrapper: this.prescriptionSummaryAdditionalNotesInputWrapper(),

      planOfCareSummarySection: this.planOfCareSummarySection(),
      planOfCareSummaryIsRequiredInputWrapper: this.planOfCareSummaryIsRequiredInputWrapper(),
      planOfCareSummaryWhenIsItRequiredInputWrapper: this.planOfCareSummaryWhenIsItRequiredInputWrapper(),
      planOfCareSummaryHowIsItSubmittedInputWrapper: this.planOfCareSummaryHowIsItSubmittedInputWrapper(),

      limitationsSummarySection: this.limitationsSummarySection(),
      limitationsSummaryHasOtherLimitationsInputWrapper: this.limitationsSummaryHasOtherLimitationsInputWrapper(),
      otherLimitationsSection: this.otherLimitationsSection(),

      upcomingVisitPatientResponsibilitySummarySection: this.upcomingVisitPatientResponsibilitySummarySection(),
      upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper: this.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper(),
      upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper: this.upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper(),
      upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper: this.upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper(),
      upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper: this.upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper(),
      upcomingVisitPatientResponsibilitySummaryOtherAmountUnitInputWrapper: this.upcomingVisitPatientResponsibilitySummaryOtherAmountUnitInputWrapper(),

      afterDeductibleIsMetPatientResponsibilitySummarySection: this.afterDeductibleIsMetPatientResponsibilitySummarySection(),
      afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper: this.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper(),
      afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper: this.afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper(),
      afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper: this.afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper(),
      afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper: this.afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper(),
      afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountUnitInputWrapper: this.afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountUnitInputWrapper(),

      subscriberSummarySection: this.subscriberSummarySection(),
      subscriberSummaryAdditionalNotesInputWrapper: this.subscriberSummaryAdditionalNotesInputWrapper(),

      additionalQuestionsSection: this.additionalQuestionsSection(),

      attachedFilesSection: this.attachedFilesSection(),
      attachedFilesHasAttachedFilesInputWrapper: this.attachedFilesHasAttachedFilesInputWrapper(),
      attachedFilesPortalSourceInputWrapper: this.attachedFilesPortalSourceInputWrapper(),
      attachedFilesFileUploadWrapper: this.attachedFilesFileUploadWrapper(),

      providerNoteSummarySection: this.providerNoteSummarySection(),
      providerNoteSummaryAccountedForInputWrapper: this.providerNoteSummaryAccountedForInputWrapper(),

      internalInformationSummarySection: this.internalInformationSummarySection(),
      internalInformationSummaryInformationSourceIdsInputWrapper: this.internalInformationSummaryInformationSourceIdsInputWrapper(),
      internalInformationSummaryTimeInMinutesToCompleteInputWrapper: this.internalInformationSummaryTimeInMinutesToCompleteInputWrapper(),
      finishedMessage: this.finishedMessage()
    }
  }


  directionsSection = () => true
  directionsProviderIdInputWrapper = () => true
  directionsServiceTypeInputWrapper = () => this.formState.directionsProviderIdInputWrapper != ''
  directionsPlaceOfServiceInputWrapper = () => this.formState.directionsServiceTypeInputWrapper != ''

  callSummarySection = () => false
  callSummaryIsCallRequiredInputWrapper = () => false

  networkStatusSummarySection() { return this.formState.directionsPlaceOfServiceInputWrapper != '' }
  networkStatusSummaryNetworkStatusInputWrapper = () => this.networkStatusSummarySection()
  networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper = () => this.formState.networkStatusSummaryNetworkStatusInputWrapper == this.conflictingNetworkStatus
  networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapper = () => (
    this.formState.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper.length > 0 &&
    this.formState.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper.includes('Other')
  )

  coverageStatusSummarySection() {
    return (
      this.formState.networkStatusSummaryNetworkStatusInputWrapper != '' &&
      this.formState.networkStatusSummaryNetworkStatusInputWrapper != this.conflictingNetworkStatus
    ) || (
      this.formState.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper.length > 0 != '' &&
      !this.formState.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper.includes('Other')
    ) || (
      this.formState.networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper.includes('Other') &&
      this.formState.networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapper != ''
    )
  }
  coverageStatusSummaryIsVerifiableInputWrapper = () => this.coverageStatusSummarySection()
  coverageStatusSummaryNotVerifiableReasonIdsInputWrapper = () => this.formState.coverageStatusSummaryIsVerifiableInputWrapper == 'false'
  coverageStatusSummaryOtherNotVerifiableReasonInputWrapper = () => this.formState.coverageStatusSummaryNotVerifiableReasonIdsInputWrapper.includes('Other')
  coverageStatusSummaryIsPlanActiveInputWrapper = () => this.formState.coverageStatusSummaryIsVerifiableInputWrapper == 'true'
  coverageStatusSummaryIsBenefitCoveredInputWrapper = () => this.formState.coverageStatusSummaryIsPlanActiveInputWrapper == 'true'
  coverageStatusSummaryTimePeriodUnitInputWrapper = () => this.formState.coverageStatusSummaryIsBenefitCoveredInputWrapper == 'true'
  coverageStatusSummaryTimePeriodStartDateInputWrapper = () => this.formState.coverageStatusSummaryTimePeriodUnitInputWrapper != '' && this.formState.coverageStatusSummaryTimePeriodUnitInputWrapper != 'not_able_to_confirm'
  coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper = () => this.coverageStatusSummaryTimePeriodStartDateInputWrapper()
  coverageStatusSummaryTimePeriodEndDateInputWrapper = () => {
    return (
      this.formState.coverageStatusSummaryTimePeriodStartDateInputWrapper != '' ||
      (
        this.formState.coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper != '' &&
        this.formState.coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper != 'undefined'
      )
    )
  }
  coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper = () => this.coverageStatusSummaryTimePeriodEndDateInputWrapper()
  coverageStatusSummaryPolicyTypeInputWrapper = () => {
    return (
      (
        this.formState.coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper != '' &&
        this.formState.coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper != 'undefined'
      ) || (
        this.formState.coverageStatusSummaryTimePeriodEndDateInputWrapper != '' &&
        this.formState.coverageStatusSummaryTimePeriodEndDateInputWrapper != 'undefined'
      ) || (
        this.formState.coverageStatusSummaryTimePeriodUnitInputWrapper == 'not_able_to_confirm'
      )
    )
  }
  coverageStatusSummaryOtherPolicyTypeInputWrapper = () => this.formState.coverageStatusSummaryPolicyTypeInputWrapper == 'Other' 

  coverageStatusSummaryPolicyNameInputWrapper = () => (
      this.formState.coverageStatusSummaryPolicyTypeInputWrapper == 'Other' &&
      this.formState.coverageStatusSummaryOtherPolicyTypeInputWrapper != ''
    ) || (
      this.formState.coverageStatusSummaryPolicyTypeInputWrapper != 'Other' &&
      this.formState.coverageStatusSummaryPolicyTypeInputWrapper != ''
    )
  coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper = () => this.coverageStatusSummaryPolicyNameInputWrapper()

  coverageStatusSummaryPolicyKindInputWrapper = () => {
    return (
      this.formState.coverageStatusSummaryPolicyNameInputWrapper != '' ||
      (
        this.formState.coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper != '' &&
        this.formState.coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper != 'undefined'
      )
    )
  }
  coverageStatusSummaryOtherPolicyKindInputWrapper() { return this.formState.coverageStatusSummaryPolicyKindInputWrapper == 'other' }

  individualDeductibleSummarySection() {
    return (
      (
        this.formState.coverageStatusSummaryPolicyKindInputWrapper == 'other' &&
        this.formState.coverageStatusSummaryOtherPolicyKindInputWrapper != ''
      ) || (
        this.formState.coverageStatusSummaryPolicyKindInputWrapper != 'other' &&
        this.formState.coverageStatusSummaryPolicyKindInputWrapper != ''
      )
    )
  }
  individualDeductibleSummaryIsPresentInputWrapper = () => this.individualDeductibleSummarySection()
  individualDeductibleSummaryIsMetInputWrapper = () => this.formState.individualDeductibleSummaryIsPresentInputWrapper == 'true'
  individualDeductibleSummaryTotalAmountInputWrapper = () => this.formState.individualDeductibleSummaryIsMetInputWrapper != ''
  individualDeductibleSummarySpentAmountInputWrapper = () => this.formState.individualDeductibleSummaryIsMetInputWrapper == 'false'
  individualDeductibleSummaryRemainingAmountInputWrapper = () => this.formState.individualDeductibleSummaryIsMetInputWrapper == 'false'

  familyDeductibleSummarySection() {
    return (
      (!this.isMedicare) &&
      (
        (this.formState.individualDeductibleSummaryIsPresentInputWrapper == 'false') ||
        (
          this.formState.individualDeductibleSummaryIsMetInputWrapper == 'true' &&
          this.formState.individualDeductibleSummaryTotalAmountInputWrapper != ''
        ) ||
        (
          this.formState.individualDeductibleSummaryTotalAmountInputWrapper != '' &&
          this.formState.individualDeductibleSummarySpentAmountInputWrapper != '' &&
          this.formState.individualDeductibleSummaryRemainingAmountInputWrapper != ''
        )
      )
    )
  }
  familyDeductibleSummaryIsPresentInputWrapper = () => this.familyDeductibleSummarySection()
  familyDeductibleSummaryIsMetInputWrapper = () => this.formState.familyDeductibleSummaryIsPresentInputWrapper == 'true'
  familyDeductibleSummaryTotalAmountInputWrapper = () => this.formState.familyDeductibleSummaryIsMetInputWrapper != ''
  familyDeductibleSummarySpentAmountInputWrapper = () => this.formState.familyDeductibleSummaryIsMetInputWrapper == 'false'
  familyDeductibleSummaryRemainingAmountInputWrapper = () => this.formState.familyDeductibleSummaryIsMetInputWrapper == 'false'

  upcomingServiceTypeVisitDeductibleApplicableStatementSummarySection() {
    return (
      (
        this.formState.individualDeductibleSummaryTotalAmountInputWrapper != '' &&
        this.formState.familyDeductibleSummaryIsPresentInputWrapper == 'false'
      ) || (
        this.formState.familyDeductibleSummaryIsMetInputWrapper == 'true' &&
        this.formState.familyDeductibleSummaryTotalAmountInputWrapper != ''
      ) ||
      (
        this.formState.familyDeductibleSummarySpentAmountInputWrapper !== '' &&
        this.formState.familyDeductibleSummaryRemainingAmountInputWrapper !== '' &&
        this.formState.familyDeductibleSummaryTotalAmountInputWrapper !== ''
      )
    )
  }
  upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper = () => this.upcomingServiceTypeVisitDeductibleApplicableStatementSummarySection()

  upcomingVisitDeductibleApplicableStatementSummarySection() {
    return (
      this.formState.upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper == 'true'
    )
  }
  upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper = () => this.upcomingVisitDeductibleApplicableStatementSummarySection()

  individualOutOfPocketMaximumSummarySection() {
    return (
      (!this.isMedicare) && (
        (
          this.formState.upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper == 'false'
        ) ||
        (
          this.formState.individualDeductibleSummaryIsPresentInputWrapper == 'false' &&
          this.formState.familyDeductibleSummaryIsPresentInputWrapper == 'false'
        ) ||
        (
          this.formState.upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper != ''
        )
      )
    )
  }
  individualOutOfPocketMaximumSummaryIsPresentInputWrapper = () => this.individualOutOfPocketMaximumSummarySection()
  individualOutOfPocketMaximumSummaryIsMetInputWrapper = () => this.formState.individualOutOfPocketMaximumSummaryIsPresentInputWrapper == 'true'
  individualOutOfPocketMaximumSummaryTotalAmountInputWrapper = () => this.formState.individualOutOfPocketMaximumSummaryIsMetInputWrapper != ''
  individualOutOfPocketMaximumSummarySpentAmountInputWrapper = () => this.formState.individualOutOfPocketMaximumSummaryIsMetInputWrapper == 'false'
  individualOutOfPocketMaximumSummaryRemainingAmountInputWrapper = () => this.formState.individualOutOfPocketMaximumSummaryIsMetInputWrapper == 'false'

  familyOutOfPocketMaximumSummarySection() {
    return (
      (!this.isMedicare) && (
        (this.formState.individualOutOfPocketMaximumSummaryIsPresentInputWrapper == 'false') ||
        (
          this.formState.individualOutOfPocketMaximumSummaryIsMetInputWrapper == 'true' &&
          this.formState.individualOutOfPocketMaximumSummaryTotalAmountInputWrapper != ''
        ) ||
        (
          this.formState.individualOutOfPocketMaximumSummaryTotalAmountInputWrapper != '' &&
          this.formState.individualOutOfPocketMaximumSummarySpentAmountInputWrapper != '' &&
          this.formState.individualOutOfPocketMaximumSummaryRemainingAmountInputWrapper != ''
        )
      )
    )
  }
  familyOutOfPocketMaximumSummaryIsPresentInputWrapper = () => this.familyOutOfPocketMaximumSummarySection()
  familyOutOfPocketMaximumSummaryIsMetInputWrapper = () => this.formState.familyOutOfPocketMaximumSummaryIsPresentInputWrapper == 'true'
  familyOutOfPocketMaximumSummaryTotalAmountInputWrapper = () => this.formState.familyOutOfPocketMaximumSummaryIsMetInputWrapper != ''
  familyOutOfPocketMaximumSummarySpentAmountInputWrapper = () => this.formState.familyOutOfPocketMaximumSummaryIsMetInputWrapper == 'false'
  familyOutOfPocketMaximumSummaryRemainingAmountInputWrapper = () => this.formState.familyOutOfPocketMaximumSummaryIsMetInputWrapper == 'false'

  upcomingVisitOutOfPocketMaximumApplicableStatementSummarySection() {
    return (
      (
        this.formState.individualOutOfPocketMaximumSummaryTotalAmountInputWrapper != '' &&
        this.formState.familyOutOfPocketMaximumSummaryIsPresentInputWrapper == 'false'
      ) || (
        this.formState.familyOutOfPocketMaximumSummaryIsMetInputWrapper == 'true' &&
        this.formState.familyOutOfPocketMaximumSummaryTotalAmountInputWrapper != ''
      ) ||
      (
        this.formState.familyOutOfPocketMaximumSummaryTotalAmountInputWrapper != '' &&
        this.formState.familyOutOfPocketMaximumSummarySpentAmountInputWrapper != '' &&
        this.formState.familyOutOfPocketMaximumSummaryRemainingAmountInputWrapper != ''
      )
    )
  } 
  upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper = () => this.upcomingVisitOutOfPocketMaximumApplicableStatementSummarySection()

  medicareSummarySection() {
    return (
      this.isMedicare && (
        (this.formState.individualDeductibleSummaryIsPresentInputWrapper == 'false') ||
        (
          this.formState.individualDeductibleSummaryIsMetInputWrapper == 'true' &&
          this.formState.individualDeductibleSummaryTotalAmountInputWrapper != ''
        ) ||
        (
          this.formState.individualDeductibleSummaryIsMetInputWrapper == 'false' &&
          this.formState.individualDeductibleSummaryTotalAmountInputWrapper != '' &&
          this.formState.individualDeductibleSummarySpentAmountInputWrapper != '' &&
          this.formState.individualDeductibleSummaryRemainingAmountInputWrapper != ''
        )
      )
    )
  }
  medicareSummaryTotalBenefitCapInputWrapper = () => this.medicareSummarySection()
  medicareSummaryRemainingBenefitCapInputWrapper = () => this.formState.medicareSummaryTotalBenefitCapInputWrapper != ''
  medicareSummaryPartInputWrapper = () => this.formState.medicareSummaryRemainingBenefitCapInputWrapper != ''
  medicareSummaryIsQmbInputWrapper = () => this.formState.medicareSummaryPartInputWrapper != ''
  medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper = () => this.formState.medicareSummaryIsQmbInputWrapper != ''
  medicareSummaryCurrentEpisodeOfHomeHealthDateInputWrapper = () => this.formState.medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper == 'true'
  visitLimitSummarySection() {
    return (
      (
        this.formState.upcomingServiceTypeVisitOutOfPocketMaximumApplicableStatementSummaryIsServiceTypeApplicableInputWrapper == 'false'
      ) ||
      (
        this.formState.upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper != ''
      ) ||
      (
        this.isMedicare && this.formState.medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper != ''
      ) || (
        this.formState.individualOutOfPocketMaximumSummaryIsPresentInputWrapper == 'false' &&
        this.formState.familyOutOfPocketMaximumSummaryIsPresentInputWrapper == 'false'
      )
    )
  }
  visitLimitSummaryHasVisitLimitInputWrapper = () => this.visitLimitSummarySection()
  visitLimitSummaryNumberAllowedInputWrapper = () => this.formState.visitLimitSummaryHasVisitLimitInputWrapper == 'true'
  visitLimitSummaryNumberUsedInputWrapper = () =>this.formState.visitLimitSummaryHasVisitLimitInputWrapper == 'true'
  visitLimitSummaryNumberRemainingInputWrapper = () => this.formState.visitLimitSummaryHasVisitLimitInputWrapper == 'true'
  visitLimitSummaryUnitInputWrapper = () => (
    this.formState.visitLimitSummaryNumberRemainingInputWrapper != '' &&
    this.formState.visitLimitSummaryNumberUsedInputWrapper != '' &&
    this.formState.visitLimitSummaryNumberAllowedInputWrapper != ''
  )
  visitLimitSummaryOtherUnitInputWrapper = () => this.formState.visitLimitSummaryUnitInputWrapper == 'other'
  visitLimitSummaryMaxKindInputWrapper = () => {
    return (
      (
        this.formState.visitLimitSummaryUnitInputWrapper != '' &&
        this.formState.visitLimitSummaryUnitInputWrapper != 'other'
      ) ||
      (
        this.formState.visitLimitSummaryUnitInputWrapper == 'other' &&
        this.formState.visitLimitSummaryOtherUnitInputWrapper != ''
      )
    )
  }
  visitLimitSummaryIsVisitLimitCombinedInputWrapper = () => this.formState.visitLimitSummaryMaxKindInputWrapper != ''
  visitLimitSummaryCombinedVisitKindIdsInputWrapper = () => this.formState.visitLimitSummaryIsVisitLimitCombinedInputWrapper == 'true'
  visitLimitSummaryOtherCombinedVisitLimitInputWrapper = () => this.formState.visitLimitSummaryCombinedVisitKindIdsInputWrapper.includes('Other')
  visitLimitSummaryHasSameDayModalityLimitInputWrapper() {
    return (
      this.formState.visitLimitSummaryHasVisitLimitInputWrapper == 'false' || (
      (
        this.formState.visitLimitSummaryCombinedVisitKindIdsInputWrapper.length > 0 &&
        !this.formState.visitLimitSummaryCombinedVisitKindIdsInputWrapper.includes('Other')
      ) || (
        this.formState.visitLimitSummaryCombinedVisitKindIdsInputWrapper.includes('Other') &&
        this.formState.visitLimitSummaryOtherCombinedVisitLimitInputWrapper != ''
      ) ||
      (this.formState.visitLimitSummaryIsVisitLimitCombinedInputWrapper == 'false') ||
      (this.formState.visitLimitSummaryIsVisitLimitCombinedInputWrapper == 'not_able_to_confirm')
    ))
  }
  visitLimitSummarySameDayModalityLimitDetailsInputWrapper = () => this.formState.visitLimitSummaryHasSameDayModalityLimitInputWrapper == 'true'
  visitLimitSummaryAdditionalNotesInputWrapper() {
    return (
      this.formState.visitLimitSummaryHasSameDayModalityLimitInputWrapper == 'false' ||
      this.formState.visitLimitSummaryHasSameDayModalityLimitInputWrapper == 'not_able_to_confirm' ||
      this.formState.visitLimitSummarySameDayModalityLimitDetailsInputWrapper != ''
    )
  }

  dollarLimitSection() {
    return (
      (this.formState.visitLimitSummaryHasSameDayModalityLimitInputWrapper == 'false') ||
      (this.formState.visitLimitSummaryHasSameDayModalityLimitInputWrapper == 'not_able_to_confirm') ||
      (this.formState.visitLimitSummarySameDayModalityLimitDetailsInputWrapper != '')
    )
  }
  dollarLimitHasLimitInputWrapper = () => this.dollarLimitSection()
  dollarLimitNumberAllowedInputWrapper = () => this.formState.dollarLimitHasLimitInputWrapper == 'true'
  dollarLimitNumberUsedInputWrapper = () => this.formState.dollarLimitHasLimitInputWrapper == 'true'
  dollarLimitNumberRemainingInputWrapper = () => this.formState.dollarLimitHasLimitInputWrapper == 'true'

  authorizationSummarySection() {
    return (
      (this.formState.dollarLimitHasLimitInputWrapper == 'false') ||
      (
        this.formState.dollarLimitNumberRemainingInputWrapper != '' &&
        this.formState.dollarLimitNumberUsedInputWrapper != '' &&
        this.formState.dollarLimitNumberAllowedInputWrapper != ''
      )
    )
  }
  authorizationSummaryIsRequiredInputWrapper = () => this.authorizationSummarySection()
  authorizationSummaryWhenIsItRequiredInputWrapper = () => this.formState.authorizationSummaryIsRequiredInputWrapper == 'true'
  authorizationSummaryHowIsItSubmittedInputWrapper = () => this.formState.authorizationSummaryWhenIsItRequiredInputWrapper != ''
  authorizationSummaryAdditionalNotesInputWrapper = () => this.formState.authorizationSummaryIsRequiredInputWrapper != ''

  medicalNecessityReviewSummarySection() {
    return (
      (this.formState.authorizationSummaryIsRequiredInputWrapper == 'false') ||
      (this.formState.authorizationSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
      (
        this.formState.authorizationSummaryIsRequiredInputWrapper == 'true' &&
        this.formState.authorizationSummaryHowIsItSubmittedInputWrapper != ''
      )
    )
  }
  medicalNecessityReviewSummaryIsRequiredInputWrapper = () => this.medicalNecessityReviewSummarySection()
  medicalNecessityReviewSummaryWhenIsItRequiredInputWrapper = () => this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'true'
  medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper = () => this.formState.medicalNecessityReviewSummaryWhenIsItRequiredInputWrapper != ''
  medicalNecessityReviewSummaryAdditionalNotesInputWrapper = () => this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper != ''

  clinicalSubmissionSummarySection() {
    return this.isUhc && (
      (this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'false') ||
      (this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
      (
        this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'true' &&
        this.formState.medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper != ''
      )
    )
  }
  clinicalSubmissionSummaryIsRequiredInputWrapper = () => this.clinicalSubmissionSummarySection()
  clinicalSubmissionSummaryWhenIsItRequiredInputWrapper = () => this.formState.clinicalSubmissionSummaryIsRequiredInputWrapper == 'true'
  clinicalSubmissionSummaryHowIsItSubmittedInputWrapper = () => this.formState.clinicalSubmissionSummaryWhenIsItRequiredInputWrapper != ''
  clinicalSubmissionSummaryAdditionalNotesInputWrapper = () => this.formState.clinicalSubmissionSummaryIsRequiredInputWrapper != ''

  referralSummarySection() {
    if (!this.isUhc) {
      return (
        (this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'false') ||
        (this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
        (
          this.formState.medicalNecessityReviewSummaryIsRequiredInputWrapper == 'true' &&
          this.formState.medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper != ''
        )
      )
    }

    return (
      (this.formState.clinicalSubmissionSummaryIsRequiredInputWrapper == 'false') ||
      (this.formState.clinicalSubmissionSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
      (
        this.formState.clinicalSubmissionSummaryIsRequiredInputWrapper == 'true' &&
        this.formState.clinicalSubmissionSummaryHowIsItSubmittedInputWrapper != ''
      )
    )
  }
  referralSummaryIsRequiredInputWrapper = () => this.referralSummarySection()
  referralSummaryWhenIsItRequiredInputWrapper = () => this.formState.referralSummaryIsRequiredInputWrapper == 'true'
  referralSummaryAdditionalNotesInputWrapper = () => this.formState.referralSummaryIsRequiredInputWrapper != ''

  prescriptionSummarySection() {
    return (
      (this.formState.referralSummaryIsRequiredInputWrapper == 'false') ||
      (this.formState.referralSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
      (
        this.formState.referralSummaryIsRequiredInputWrapper == 'true' &&
        this.formState.referralSummaryWhenIsItRequiredInputWrapper != ''
      )
    )
  }
  prescriptionSummaryIsRequiredInputWrapper = () => this.prescriptionSummarySection()
  prescriptionSummaryWhenIsItRequiredInputWrapper = () => this.formState.prescriptionSummaryIsRequiredInputWrapper == 'true'
  prescriptionSummaryHowIsItSubmittedInputWrapper = () => this.formState.prescriptionSummaryWhenIsItRequiredInputWrapper != ''
  prescriptionSummaryAdditionalNotesInputWrapper = () => this.formState.prescriptionSummaryIsRequiredInputWrapper != ''

  planOfCareSummarySection() {
    return (this.isMedicare && this.planOfCareConditions())
  }
  planOfCareSummaryIsRequiredInputWrapper = () => this.planOfCareSummarySection()
  planOfCareSummaryWhenIsItRequiredInputWrapper = () => this.formState.planOfCareSummaryIsRequiredInputWrapper == 'true'
  planOfCareSummaryHowIsItSubmittedInputWrapper = () => this.formState.planOfCareSummaryWhenIsItRequiredInputWrapper != ''

  limitationsSummarySection() {
    return (
      (
        this.isMedicare && (
          (this.formState.planOfCareSummaryIsRequiredInputWrapper == 'false') ||
          (this.formState.planOfCareSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
          (
            this.formState.planOfCareSummaryIsRequiredInputWrapper == 'true' &&
            this.formState.planOfCareSummaryHowIsItSubmittedInputWrapper != ''
          )
        )
      ) ||
      (!this.isMedicare && this.planOfCareConditions())
    )
  }

  limitationsSummaryHasOtherLimitationsInputWrapper = () => this.limitationsSummarySection()
  otherLimitationsSection = () => this.formState.limitationsSummaryHasOtherLimitationsInputWrapper == 'true'

  upcomingVisitPatientResponsibilitySummarySection() {
    return (this.formState.limitationsSummaryHasOtherLimitationsInputWrapper != '')
  }
  upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper = () => this.upcomingVisitPatientResponsibilitySummarySection()
  upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper = () => this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Pay')
  upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper = () => this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Insurance')
  upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper = () => this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')
  upcomingVisitPatientResponsibilitySummaryOtherAmountUnitInputWrapper = () => this.formState.upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper != ''

  afterDeductibleIsMetPatientResponsibilitySummarySection() {
    return (
        this.formState.upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper != '' &&
        !this.isDeductibleMet && this.upcomingVisitConditions()
      ) || (
        this.isMedicare && (
          !this.isDeductibleMet &&
          this.upcomingVisitConditions()
        )
      )
  }
  afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper = () => this.afterDeductibleIsMetPatientResponsibilitySummarySection()
  afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper = () => this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Pay')
  afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper = () => this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Insurance')
  afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper = () => this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')
  afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountUnitInputWrapper = () => this.formState.afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper != ''
  
  subscriberSummarySection() {
    return (
      this.subscriberSummarySectionConditions() ||
      this.internalInformationSummarySectionConditions()
    )
  }
  subscriberSummaryAdditionalNotesInputWrapper = () => this.subscriberSummarySection()

  providerNoteSummarySection() {
    return (
      this.subscriberSummarySection() &&
      !this.inactiveBenefitConditions()
    )
  }
  providerNoteSummaryAccountedForInputWrapper = () => this.providerNoteSummarySection()

  internalInformationSummarySection() {
    return this.internalInformationSummarySectionConditions()
  }
  internalInformationSummaryInformationSourceIdsInputWrapper = () => this.internalInformationSummarySection()
  internalInformationSummaryTimeInMinutesToCompleteInputWrapper = () => this.formState.internalInformationSummaryInformationSourceIdsInputWrapper != ''
  finishedMessage = () => this.formState.internalInformationSummaryTimeInMinutesToCompleteInputWrapper.length >= 2
  setSectionStatus = () => {
    if(this.formState.internalInformationSummaryTimeInMinutesToCompleteInputWrapper.length >= 2){
      this.sectionStatus = 'complete'
    }else{
      this.sectionStatus = 'incomplete'
    }
  }

  subscriberSummarySectionConditions() {
    return (
      (
        (this.isDeductibleMet || this.planHasNoDeductible() || this.visitsAreNotSubjectToDeductible()) && this.upcomingVisitConditions()
      ) ||
      (this.isMedicare && this.upcomingVisitConditions()) ||
      (
        !this.isDeductibleMet &&
        (
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper != '' &&
          !['Co Pay', 'Co Insurance', 'Other'].some(r=> this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.toString().split(',').includes(r))
        ) ||
        (
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay'] &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper != ''
        ) ||
        (
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Insurance'] &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper != ''
        ) ||
        (
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay', 'Co Insurance'] &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper != '' &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper != ''
        ) ||
        (
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Other'] &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper != '' &&
          this.formState.afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountUnitInputWrapper != ''
        )
      )
    )
  }
  subscriberSummaryAdditionalNotesInputWrapper = () => this.subscriberSummarySection()

  additionalQuestionsSection = () => this.subscriberSummarySection()

  attachedFilesSection = () => this.subscriberSummarySection() || this.afterDeductibleIsMetPatientResponsibilitySummarySection()
  attachedFilesHasAttachedFilesInputWrapper = () => this.attachedFilesSection()
  attachedFilesPortalSourceInputWrapper = () => this.formState.attachedFilesHasAttachedFilesInputWrapper == 'true'
  attachedFilesFileUploadWrapper = () => this.formState.attachedFilesPortalSourceInputWrapper != '' 

  providerNoteSummarySection() {
    return this.attachedFilesSection()
  }
  providerNoteSummaryAccountedForInputWrapper = () => this.providerNoteSummarySection()

  internalInformationSummarySectionConditions() {
    return (
      this.formState.providerNoteSummaryAccountedForInputWrapper != '' ||
      this.inactiveBenefitConditions()
    )
  }
  inactiveBenefitConditions() {
    return (
      (
        this.formState.coverageStatusSummaryNotVerifiableReasonIdsInputWrapper != '' &&
        !this.formState.coverageStatusSummaryNotVerifiableReasonIdsInputWrapper.includes('Other')
      ) ||
      (
        this.formState.coverageStatusSummaryNotVerifiableReasonIdsInputWrapper.includes('Other') &&
        this.formState.coverageStatusSummaryOtherNotVerifiableReasonInputWrapper != ''
      ) ||
      (this.formState.coverageStatusSummaryIsPlanActiveInputWrapper == 'false') ||
      (this.formState.coverageStatusSummaryIsBenefitCoveredInputWrapper == 'false')
    )
  }
  planOfCareConditions() {
    return (
      (this.formState.prescriptionSummaryIsRequiredInputWrapper == 'false') ||
      (this.formState.prescriptionSummaryIsRequiredInputWrapper == 'not_able_to_confirm') ||
      (
        this.formState.prescriptionSummaryIsRequiredInputWrapper == 'true' &&
        this.formState.prescriptionSummaryHowIsItSubmittedInputWrapper != ''
      )
    )
  }
  upcomingVisitConditions() {
    return (
      (
        this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper != '' &&
        !['Co Pay', 'Co Insurance', 'Other'].some(r=> this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper.toString().split(',').includes(r))
      ) ||
      (
        this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay'] &&
        this.formState.upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper != ''
      ) ||
      (
        this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Insurance'] &&
        this.formState.upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper != ''
      ) ||
      (
        this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay', 'Co Insurance'] &&
        this.formState.upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper != '' &&
        this.formState.upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper != ''
      ) ||
      (
        this.formState.upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper == ['Other'] &&
        this.formState.upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper != '' &&
        this.formState.upcomingVisitPatientResponsibilitySummaryOtherAmountUnitInputWrapper != ''
      )
    )
  }
  planHasNoDeductible() {
    return (
      this.formState.individualDeductibleSummaryIsPresentInputWrapper == 'false' &&
      this.formState.familyDeductibleSummaryIsPresentInputWrapper == 'false'
    )
  }
  visitsAreNotSubjectToDeductible() {
    return (
      this.formState.upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper == 'false'
    )
  }
}
