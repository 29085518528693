import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["contents", "tooltip"];
  static values = {
    position: String,
  };

  connect() {
    this.popperInstance = createPopper(
      this.contentsTarget,
      this.tooltipTarget,
      {
        placement: this.positionValue,
      }
    );
  }

  show(event) {
    this.tooltipTarget.setAttribute("data-show", "");
    this.popperInstance.update();
  }

  hide(event) {
    this.tooltipTarget.removeAttribute("data-show");
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
