import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    input: String,
  };

  copy(e) {
    e.preventDefault();
    let input = document.createElement('input');
    input.setAttribute('value', this.inputValue);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    const modalElement = document.getElementById("confirmation-modal");
    const modalHeader = document.getElementById("confirmation-modal-header");
    const modalTitle = document.getElementById("confirmation-modal-title");
    const modalContent = document.getElementById("confirmation-modal-content");
    const modalOk = document.getElementById("confirmation-modal-ok");
    const modalCancel = document.getElementById("confirmation-modal-cancel");
    modalHeader.style.display = 'none';
    modalCancel.style.display = 'none';
    modalTitle.innerHTML = 'Copy to clipboard';
    modalContent.innerHTML = `${this.titleValue} was copied to your clipboard`;
  
    modalElement.classList.remove("hidden");
    modalOk.addEventListener("click", () => {
      modalElement.classList.add("hidden");
    });

    return false;
  }
}

