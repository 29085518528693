import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    delay: Number,
  };

  initialize() {
    this.submit = this.submit.bind(this);
  }

  connect() {
    let inputs = this.element.querySelectorAll('input')
    inputs.forEach((el) => {
      if (el.type == 'search'){
        el.addEventListener('keyup', _.debounce(this.submit, this.delayValue));
      } else if (el.type == 'date'){
        el.addEventListener('change', _.debounce(this.submit, this.delayValue));
      }
    });
  }

  submit() {
    if (!window._rails_loaded) return;
    Rails.fire(this.element, "submit");
  }
}
