import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    delay: 3000
  };

  initialize() {
    this.save = this.save.bind(this);
  }

  connect() {
    if (this.delayValue > 0) {
      this.save = _.debounce(this.save, this.delayValue);
    }
  }

  save() {
    if (!window._rails_loaded) return;

    // remove all error messages on submit (useful with turbo_streams)
    document
      .querySelectorAll(".alert-danger")
      .forEach((el) => el.classList.add("d-none"));
    document
      .querySelectorAll(".is-invalid")
      .forEach((el) => el.classList.remove("is-invalid"));
    document.querySelectorAll(".invalid-feedback").forEach((e) => e.remove());

    Rails.fire(this.element, "submit");
  }

  onSaveSuccess(event) {
    let resourceFrames = document.querySelectorAll(".subscriber-summary-resource");

    resourceFrames.forEach((el) => {
      el.reload()
    });
  }
}
