import $ from 'jquery'
import FormRules from './formRules'

export default class {
  constructor(controller) {
    this.controller = controller
    this.updateFields()
  }

  updateFields = () => {
    var previousFormState = {}
    var nextFormState = this.#latestFormState()

    while (JSON.stringify(previousFormState) !== JSON.stringify(nextFormState)) {
      let formRules = new FormRules({
        formState: nextFormState,
        expectedNetworkStatus: this.controller.expectedNetworkStatusValue,
        isMedicare: this.controller.isMedicareValue,
        isUhc: this.controller.isUhcValue,
        isDeductibleMet: this.controller.getDeductibleMetValue()
      })

      this.controller.toggleSectionStatus(formRules.sectionStatus)
      let formRulesResult = formRules.result

      let deductibleStatus = this.controller.getDeductibleMetValue()
      this.controller.deductibleIsMet(deductibleStatus)

      Object.keys(formRulesResult).forEach(key=>{
        const target = this.controller.targets.find(key)

        if (!target) {
          console.warn(`No target found for ${key}`)
          return
        }

        let result = formRulesResult[key]
        
        if (result) this.#show(target)
        if (!result) this.#hide(target)

        this.defaultIndividualDeductibleTotalAmount(key, target)
        this.defaultMedicareTotalBenefitCap(key, target)
      })

      previousFormState = nextFormState
      nextFormState = this.#latestFormState()
    }
  }

  defaultIndividualDeductibleTotalAmount(key, target) {
    if(key == 'individualDeductibleSummaryTotalAmountInputWrapper' && this.controller.isMedicareValue){
      if(target.children[1].value == ''){
        target.children[1].value = this.controller.totalDeductibleValue
      }
    }
  }

  defaultMedicareTotalBenefitCap(key, target) {
    if(key == 'medicareSummaryTotalBenefitCapInputWrapper' && this.controller.isMedicareValue){
      if(target.children[1].value == ''){
        target.children[1].value = this.controller.totalCapValue
      }
    }
  }

  #latestFormState = () => {
    return this.controller.allInputWrappers().reduce((obj, inputWrapperTargetName)=> {
      obj[inputWrapperTargetName] = this.#getTargetInputDataValue(this.controller[`${inputWrapperTargetName}Target`])
      return obj
    },{})
  }

  #getTargetInputDataValue = (targetWrapper) => {
    return targetWrapper.getAttribute('data-value')
  }

  #getTargetInputValue = (targetWrapper) => {

    // if radio button is selected
    let selectedRadioButton = targetWrapper.querySelector("input[type=radio]:checked")
    if (selectedRadioButton) return selectedRadioButton.value

    // if boolean
    let checkboxes = targetWrapper.querySelectorAll("input[type=checkbox]")
    if (checkboxes.length == 1) {
      if (!checkboxes[0].checked) return
      
      let targetInput = targetWrapper.children[0]
      if (!targetInput) return
      return targetInput.innerText
    }

    // if checkbox return all checked labels
    let selectedCheckboxes = targetWrapper.querySelectorAll("input[type=checkbox]:checked")
    if (selectedCheckboxes.length > 0) return Array.prototype.slice.call(selectedCheckboxes).map(i=> i.parentElement.innerText)

    let targetInput = this.#getInputFromTarget(targetWrapper)

    // if no input return undefined
    if (!targetInput) return

    // else, return target input value
    return targetInput.value
  }

  #getInputFromTarget = (target) => {
    return target.children[1]
  }

  #show = (target) => {
    target.classList.remove("hidden")
    let dataValue = this.#getTargetInputValue(target)
    target.setAttribute('data-value', dataValue)
  }

  #hide = (target) => {
    target.classList.add("hidden")
    target.setAttribute('data-value', '')
    // let inputWrapperEl = $(target)
    // let inputEl = $(inputWrapperEl.children()[1])

    // // if radio button is selected
    // let selectedRadioButtons = target.querySelectorAll("input[type=radio]:checked")
    // if (selectedRadioButtons) selectedRadioButtons.forEach((item)=>{ item.checked = false })
    
    // // if checkbox return all checked labels
    // let selectedCheckboxes = target.querySelectorAll("input[type=checkbox]:checked")
    // if (selectedCheckboxes) selectedCheckboxes.forEach((item)=>{$(item).prop('checked', false)})

    // // if nested fields
    // let nestedFields = target.querySelectorAll(".nested-fields")
    // if (nestedFields){
    //   nestedFields.forEach((item)=>{
    //     $(item).find('input').each((i, input) => { $(input).val('') });
    //     $(item).find('textarea').val('');
    //   })
    // }
    
    // inputEl.val('').trigger('change')
  }
}