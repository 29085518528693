import { Controller } from "@hotwired/stimulus"
import SubscriberSummaryFormFieldVisibilityResolver from "./form_field_resolver"

export default class extends Controller {
  static values = {
    subscriberSummaryId: String,
    subscriberId: String,
    sectionStatus: String,
    formState: {},
    expectedNetworkStatus: String,
    isMedicare: Boolean,
    isUhc: Boolean,
    totalDeductible: Number,
    totalCap: Number,
    hasCall: Boolean,
    isAvaility: Boolean,
    coverageStatusSummaryIsBenefitCoveredLabelTemplate: "Does this plan have active [service_type] benefits?",
    medicareSummaryTotalBenefitCapLabelTemplate: "What is the patient's "+new Date().getFullYear()+" total Medicare cap for [service_type]?",
    medicareSummaryRemainingBenefitCapLabelTemplate: "What is this patient's remaining "+new Date().getFullYear()+" Medicare cap for [service_type]?"
  }

  static targets = [
    "directionsSection",
    "directionsProviderIdInputWrapper",
    "directionsServiceTypeInputWrapper",
    "directionsPlaceOfServiceInputWrapper",
    "callSummarySection",
    "callSummaryIsCallRequiredInputWrapper",
    "coverageStatusSummarySection",
    "coverageStatusSummaryIsVerifiableInputWrapper",
    "coverageStatusSummaryNotVerifiableReasonIdsInputWrapper",
    "coverageStatusSummaryOtherNotVerifiableReasonInputWrapper",
    "coverageStatusSummaryIsPlanActiveInputWrapper",
    "coverageStatusSummaryIsBenefitCoveredInputWrapper",
    "coverageStatusSummaryTimePeriodUnitInputWrapper",
    "coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapper",
    "coverageStatusSummaryTimePeriodStartDateInputWrapper",
    "coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapper",
    "coverageStatusSummaryTimePeriodEndDateInputWrapper",
    "coverageStatusSummaryPolicyTypeInputWrapper",
    "coverageStatusSummaryOtherPolicyTypeInputWrapper",
    "coverageStatusSummaryPolicyNameInputWrapper",
    "coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapper",
    "coverageStatusSummaryPolicyKindInputWrapper",
    "coverageStatusSummaryOtherPolicyKindInputWrapper",
    "networkStatusSummarySection",
    "networkStatusSummaryNetworkStatusInputWrapper",
    "networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapper",
    "networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapper",
    "individualDeductibleSummarySection",
    "individualDeductibleSummaryIsPresentInputWrapper",
    "individualDeductibleSummaryIsMetInputWrapper",
    "individualDeductibleSummaryRemainingAmountInputWrapper",
    "individualDeductibleSummarySpentAmountInputWrapper",
    "individualDeductibleSummaryTotalAmountInputWrapper",
    "familyDeductibleSummarySection",
    "familyDeductibleSummaryIsPresentInputWrapper",
    "familyDeductibleSummaryIsMetInputWrapper",
    "familyDeductibleSummaryRemainingAmountInputWrapper",
    "familyDeductibleSummarySpentAmountInputWrapper",
    "familyDeductibleSummaryTotalAmountInputWrapper",
    "upcomingServiceTypeVisitDeductibleApplicableStatementSummarySection",
    "upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapper",
    "upcomingVisitDeductibleApplicableStatementSummarySection",
    "upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper",
    "individualOutOfPocketMaximumSummarySection",
    "individualOutOfPocketMaximumSummaryIsPresentInputWrapper",
    "individualOutOfPocketMaximumSummaryIsMetInputWrapper",
    "individualOutOfPocketMaximumSummaryRemainingAmountInputWrapper",
    "individualOutOfPocketMaximumSummarySpentAmountInputWrapper",
    "individualOutOfPocketMaximumSummaryTotalAmountInputWrapper",
    "familyOutOfPocketMaximumSummarySection",
    "familyOutOfPocketMaximumSummaryIsPresentInputWrapper",
    "familyOutOfPocketMaximumSummaryIsMetInputWrapper",
    "familyOutOfPocketMaximumSummaryRemainingAmountInputWrapper",
    "familyOutOfPocketMaximumSummarySpentAmountInputWrapper",
    "familyOutOfPocketMaximumSummaryTotalAmountInputWrapper",
    "upcomingVisitOutOfPocketMaximumApplicableStatementSummarySection",
    "upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapper",
    "medicareSummarySection",
    "medicareSummaryTotalBenefitCapInputWrapper",
    "medicareSummaryRemainingBenefitCapInputWrapper",
    "medicareSummaryPartInputWrapper",
    "medicareSummaryIsQmbInputWrapper",
    "medicareSummaryHasCurrentEpisodeOfHomeHealthInputWrapper",
    "medicareSummaryCurrentEpisodeOfHomeHealthDateInputWrapper",
    "visitLimitSummarySection",
    "visitLimitSummaryHasVisitLimitInputWrapper",
    "visitLimitSummaryNumberAllowedInputWrapper",
    "visitLimitSummaryNumberUsedInputWrapper",
    "visitLimitSummaryNumberRemainingInputWrapper",
    "visitLimitSummaryUnitInputWrapper",
    "visitLimitSummaryOtherUnitInputWrapper",
    "visitLimitSummaryMaxKindInputWrapper",
    "visitLimitSummaryIsVisitLimitCombinedInputWrapper",
    "visitLimitSummaryCombinedVisitKindIdsInputWrapper",
    "visitLimitSummaryOtherCombinedVisitLimitInputWrapper",
    "visitLimitSummaryHasSameDayModalityLimitInputWrapper",
    "visitLimitSummarySameDayModalityLimitDetailsInputWrapper",
    "visitLimitSummaryAdditionalNotesInputWrapper",
    "dollarLimitSection",
    "dollarLimitHasLimitInputWrapper",
    "dollarLimitNumberAllowedInputWrapper",
    "dollarLimitNumberUsedInputWrapper",
    "dollarLimitNumberRemainingInputWrapper",
    "authorizationSummarySection",
    "authorizationSummaryIsRequiredInputWrapper",
    "authorizationSummaryWhenIsItRequiredInputWrapper",
    "authorizationSummaryHowIsItSubmittedInputWrapper",
    "authorizationSummaryAdditionalNotesInputWrapper",
    "medicalNecessityReviewSummarySection",
    "medicalNecessityReviewSummaryIsRequiredInputWrapper",
    "medicalNecessityReviewSummaryWhenIsItRequiredInputWrapper",
    "medicalNecessityReviewSummaryHowIsItSubmittedInputWrapper",
    "medicalNecessityReviewSummaryAdditionalNotesInputWrapper",
    "clinicalSubmissionSummarySection",
    "clinicalSubmissionSummaryIsRequiredInputWrapper",
    "clinicalSubmissionSummaryWhenIsItRequiredInputWrapper",
    "clinicalSubmissionSummaryHowIsItSubmittedInputWrapper",
    "referralSummarySection",
    "referralSummaryIsRequiredInputWrapper",
    "referralSummaryWhenIsItRequiredInputWrapper",
    "referralSummaryAdditionalNotesInputWrapper",
    "prescriptionSummarySection",
    "prescriptionSummaryIsRequiredInputWrapper",
    "prescriptionSummaryWhenIsItRequiredInputWrapper",
    "prescriptionSummaryHowIsItSubmittedInputWrapper",
    "prescriptionSummaryAdditionalNotesInputWrapper",
    "planOfCareSummarySection",
    "planOfCareSummaryIsRequiredInputWrapper",
    "planOfCareSummaryWhenIsItRequiredInputWrapper",
    "planOfCareSummaryHowIsItSubmittedInputWrapper",
    "limitationsSummarySection",
    "limitationsSummaryHasOtherLimitationsInputWrapper",
    "otherLimitationsSection",
    // "otherLimitationBodyInputWrapper",
    "upcomingVisitPatientResponsibilitySummarySection",
    "upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper",
    "upcomingVisitPatientResponsibilitySummaryCopayAmountInputWrapper",
    "upcomingVisitPatientResponsibilitySummaryCoinsuranceAmountInputWrapper",
    "upcomingVisitPatientResponsibilitySummaryOtherAmountInputWrapper",
    "upcomingVisitPatientResponsibilitySummaryOtherAmountUnitInputWrapper",
    "afterDeductibleIsMetPatientResponsibilitySummarySection",
    "afterDeductibleIsMetPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapper",
    "afterDeductibleIsMetPatientResponsibilitySummaryCopayAmountInputWrapper",
    "afterDeductibleIsMetPatientResponsibilitySummaryCoinsuranceAmountInputWrapper",
    "afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountInputWrapper",
    "afterDeductibleIsMetPatientResponsibilitySummaryOtherAmountUnitInputWrapper",
    "subscriberSummarySection",
    "subscriberSummaryAdditionalNotesInputWrapper",
    "additionalQuestionsSection",
    "attachedFilesSection",
    "attachedFilesHasAttachedFilesInputWrapper",
    "attachedFilesPortalSourceInputWrapper",
    "attachedFilesFileUploadWrapper",
    "providerNoteSummarySection",
    "providerNoteSummaryAccountedForInputWrapper",
    "internalInformationSummarySection",
    "internalInformationSummaryInformationSourceIdsInputWrapper",
    "internalInformationSummaryTimeInMinutesToCompleteInputWrapper",
    "finishedMessage",
    "jumpToBackground",
    "jumpToDropdown",
    "jumpToSection"
  ]

  static wrapperServiceTypeLabelTargets = [
    "coverageStatusSummaryIsBenefitCoveredInputWrapper",
    "medicareSummaryTotalBenefitCapInputWrapper",
    "medicareSummaryRemainingBenefitCapInputWrapper"
  ]

  connect() {
    this.setDataValue()
    this.subscriberSummaryFormFieldVisibilityResolver = new SubscriberSummaryFormFieldVisibilityResolver(this)
    this.handleChange()
    this.setServiceTypeQuestionsLabel()
    this.updateNetworkStatusQuestionsAndAnswer()
    this.updateApplicableStatementAnswers()
    this.setUpcomingVisitDeductible()
    this.autoCheckCall()
    this.autoCheckAvaility()
    this.updateServiceTypeApplicableLabel()
  }

  handleChange(event) {
    if (event !== undefined) {
      let target = event.currentTarget.closest('div[data-subscriber-summary-form-target]')
      let targetName = target.getAttribute('data-subscriber-summary-form-target');
      let dataValue = this.#getTargetInputValue(this[`${targetName}Target`])
      this[`${targetName}Target`].setAttribute('data-value', dataValue);
      this.lastUpdatedTargetName = targetName
    }
    this.autoCheckCall()
    this.autoCheckAvaility()
    this.subscriberSummaryFormFieldVisibilityResolver.updateFields()
  }

  toggleSectionStatus(status) {
    this.sectionStatusValue = status
    this.dispatch("statusUpdated", {
      detail: {
        subscriberId: this.subscriberSummaryIdValue,
        status: this.sectionStatusValue,
        showNextSection: this.#showNextSection()
      }
    })
  }

  deductibleIsMet(deductible_status) {
    this.dispatch("deductibleUpdated", { detail: { deductible_status: deductible_status } })
  }

  getDeductibleMetValue() {
    let deductibleMet = false
    let deductibleTarget = this['upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapperTarget']
    let option = deductibleTarget.querySelector("input[type=radio]:checked")
    if(option){
      if(option.closest('label').textContent.includes('has been met and is applied')) {
        deductibleMet = true
        this.hideDeductible()
      }else{
        this.showDeductible()
      }
    }
    return deductibleMet
  }

  hideDeductible() {
    let upcomingVisitTarget = this['upcomingVisitPatientResponsibilitySummarySectionTarget']
    let options = upcomingVisitTarget.querySelectorAll("span.checkbox")
    options.forEach(option => {
      let label = option.querySelector('label')
      if (label.textContent == 'Deductible') {
        label.childNodes[0].checked = false
        option.classList.add('hidden')
      }
    })
  }

  showDeductible() {
    let upcomingVisitTarget = this['upcomingVisitPatientResponsibilitySummarySectionTarget']
    let options = upcomingVisitTarget.querySelectorAll("span.checkbox")
    options.forEach(option => {
      let label = option.querySelector('label').textContent
      if (label == 'Deductible') { option.classList.remove('hidden') }
    })
  }

  updateServiceTypeQuestions(e){
    let label = e.target.options[e.target.selectedIndex].text
    this.constructor.wrapperServiceTypeLabelTargets.map(targetName =>
      this.updateLabelFor(
        targetName,
        label,
        'service_type'
      )
    )

    this.updateApplicableStatementAnswers()
    this.updateServiceTypeApplicableLabel()
  }

  setServiceTypeQuestionsLabel(){
    let serviceType = this['directionsServiceTypeInputWrapperTarget'].children[1]
    let label = serviceType.options[serviceType.selectedIndex].text
    if(label != ''){
      this.constructor.wrapperServiceTypeLabelTargets.map(targetName =>
        this.updateLabelFor(
          targetName,
          label,
          'service_type'
        )
      )
    }
  }

  updateNetworkStatusQuestionsAndAnswer(){
    let expectedNetworkStatus = this.titleCase(this.expectedNetworkStatusValue)
    let conflictingNetworkStatus = this.expectedNetworkStatusValue == 'in_network' ? 'Out Of Network' : 'In Network'

    let questionTarget = this['networkStatusSummaryConflictingNetworkStatusReasonIdsInputWrapperTarget']
    questionTarget.querySelector('label').innerHTML = "The provider told us they are "+ expectedNetworkStatus +" with this payer. please explain why you think it is "+ conflictingNetworkStatus +" and are therefore completing this VOB report for "+ conflictingNetworkStatus +" benefits?"
    let answers = questionTarget.querySelectorAll('span.checkbox')
    answers.forEach(item => {
      let label = item.querySelector('label')
      let labelText = label.childNodes[1].textContent
      label.removeChild(label.childNodes[1]);
      label.appendChild(new Text(labelText.replace('network_status', conflictingNetworkStatus)));
    })

    let otherQuestionTarget = this['networkStatusSummaryOtherConflictingNetworkStatusReasonInputWrapperTarget']
    otherQuestionTarget.querySelector('label').innerHTML = "What is the other reason you are saying "+ conflictingNetworkStatus +" benefits for this VOB?"
  }

  updateApplicableStatementAnswers() {
    let deductibleTarget = this['upcomingVisitDeductibleApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapperTarget']
    this.replaceStatementOptionLabel(deductibleTarget, 'Deductible')

    let outOfPocketTarget = this['upcomingVisitOutOfPocketMaximumApplicableStatementSummaryAmountApplicableStatementOptionIdsInputWrapperTarget']
    this.replaceStatementOptionLabel(outOfPocketTarget, 'Out Of Pocket')
  }

  replaceStatementOptionLabel(target, type) {
    let serviceTypeTarget = this['directionsServiceTypeInputWrapperTarget'].children[1]
    let serviceType = serviceTypeTarget.options[serviceTypeTarget.selectedIndex].text
    let answers = target.querySelectorAll('span.radio')
    answers.forEach(item => {
      let label = item.querySelector('label')
      let labelText = label.childNodes[1].textContent
      label.removeChild(label.childNodes[1]);
      label.appendChild(new Text(labelText.replace('level_type', type).replace('service_type', serviceType)));
    })
  }

  updateServiceTypeApplicableLabel() {
    let serviceTypeTarget = this['directionsServiceTypeInputWrapperTarget'].children[1]
    let serviceType = serviceTypeTarget.options[serviceTypeTarget.selectedIndex].text

    let serviceTypeDeductibleLabel = this['upcomingServiceTypeVisitDeductibleApplicableStatementSummaryIsServiceTypeApplicableInputWrapperTarget'].querySelector('label')
    this.updateLabelWithServiceType(serviceTypeDeductibleLabel, serviceType)
  }

  updateLabelWithServiceType(label, serviceType) {
    let labelText = label.textContent
    label.removeChild(label.childNodes[0]);
    label.appendChild(new Text(labelText.replace('service_type', serviceType)))
  }

  titleCase(str) {
    return str.replaceAll('_', ' ').toLowerCase().replace(/(^|\s)(\w)/g, function(x) {
      return x.toUpperCase();
    });
  }

  updateLabelFor(targetName, label, symbol) {
    let template = this[`${targetName.split("InputWrapper")[0]}LabelTemplateValue`]
    let newLabel = template.replace(`[${symbol}]`, label)
    this[`${targetName}Target`].querySelector('label').innerHTML = newLabel
  }

  prepopulatePermissionAnswear(event){
    let wrapper = event.target.closest('div[data-subscriber-summary-form-target]')
    let wrapperName = wrapper.getAttribute("data-subscriber-summary-form-target")
    let key = wrapperName.replace("SummaryIsRequiredInputWrapper", '')
    let whenTarget = this[`${key}SummaryWhenIsItRequiredInputWrapperTarget`]
    let howTarget = this[`${key}SummaryHowIsItSubmittedInputWrapperTarget`]
    let answersKey = key.split(/(?=[A-Z])/).join(' ').toLowerCase()
    if(event.target.value){
      if(whenTarget.children[1].value == '') {
        whenTarget.children[1].value = `${this.capitalize(answersKey)} is required`
      }
      if(howTarget.children[1].value == '') {
        howTarget.children[1].value = `${this.capitalize(answersKey)} is submitted by`
      }
    }
  }

  setDataValue() {
    let targets = this.#allTargets()
    targets.forEach(target => {
      let dataValue = this.#getTargetInputValue(this[`${target}Target`])
      this[`${target}Target`].setAttribute('data-value', dataValue);
    })
  }

  loadVisibleSection(event) {
    this.jumpToSectionTarget.innerHTML = '';
    let visibleSections = this.visibleSection();
    visibleSections.forEach(sectionName => {
      let label = this[`${sectionName}Target`].querySelector('.block.font-bold')
      if(label){
        let boxLink = document.createElement("div");
        let link = `<div class="block w-full hover:bg-gray-50 active:bg-gray-100 text-left px-3 py-2 cursor-pointer" data-action="click->subscriber-summary-form#jumpToSection" data-section-name=${sectionName}>
                      ${label.textContent}
                  </div>`;
        boxLink.innerHTML = link;
        this.jumpToSectionTarget.appendChild(boxLink)
      }
    })
  }

  jumpToSection(event) {
    let sectionName = event.currentTarget.getAttribute('data-section-name')
    this[`${sectionName}Target`].scrollIntoView()
    this.jumpToBackgroundTarget.classList.add("hidden")
    this.jumpToDropdownTarget.classList.add("hidden")
  }

  visibleSection() {
    return(
      this.allSections().filter(section => !this[`${section}Target`].classList.contains("hidden")).map(section=>section)
    )
  }

  allSections = () => this.#allTargets().filter( item => item.slice(item.length - "Section".length) == 'Section' ).map(item=>item)

  setUpcomingVisitDeductible() {
    let individualRemainingTarget = this['individualDeductibleSummaryRemainingAmountInputWrapperTarget']
    let individualRemainingTargetInput = this.#getInputFromTarget(individualRemainingTarget)
    let familyRemainingTarget = this['familyDeductibleSummaryRemainingAmountInputWrapperTarget']
    let familyRemainingTargetInput = this.#getInputFromTarget(familyRemainingTarget)
    let deductibleTarget = this['upcomingVisitPatientResponsibilitySummaryPatientResponsibilityKindIdsInputWrapperTarget']

    if(individualRemainingTargetInput.value != '' || familyRemainingTargetInput.value != '') {
      let selectedCheckboxes = deductibleTarget.querySelectorAll("input[type=checkbox]:checked")
      let kinds = deductibleTarget.querySelectorAll('.checkbox label')
      kinds.forEach(kind => {
        if(selectedCheckboxes.length == 0 && kind.textContent == 'Deductible') {
          kind.childNodes[0].checked = true
        }
      });
    }
  }

  autoCheckAvaility() {
    if(this.isAvailityValue) {
      let sourceIdTarget = this.internalInformationSummaryInformationSourceIdsInputWrapperTarget
      let sourceIds = sourceIdTarget.querySelectorAll('.checkbox label')
      sourceIds.forEach(sourceId => {
        if(sourceId.textContent == 'Availity') { sourceId.childNodes[0].checked = true }
      });
    }
  }

  autoCheckCall() {
    let callInfo = false
    let callInfoFrame = document.querySelector(`[id=subscriber_summary_${this.subscriberSummaryIdValue}_call_information]`)
    if(callInfoFrame != null) {
      let chatBox = callInfoFrame.querySelector('[data-controller="chat-box"]')
      if(callInfoFrame.getAttribute('src') == null && chatBox != null) { callInfo = true }
    }

    if(this.hasCallValue || callInfo) {
      let sourceIdTarget = this.internalInformationSummaryInformationSourceIdsInputWrapperTarget
      let sourceIds = sourceIdTarget.querySelectorAll('.checkbox label')
      sourceIds.forEach(sourceId => {
        if(sourceId.textContent == 'Call') { sourceId.childNodes[0].checked = true }
      });
    }
  }

  autoFillPeriodDate(event) {
    let value = event.target.value
    
    let periodStartDate = this['coverageStatusSummaryTimePeriodStartDateInputWrapperTarget']
    let periodStartDateNotAbleToConfirm = this['coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapperTarget']

    let periodEndDate = this['coverageStatusSummaryTimePeriodEndDateInputWrapperTarget']
    let periodEndDateNotAbleToConfirm = this['coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapperTarget']
   
    let periodStartDateInput = this.#getInputFromTarget(periodStartDate)
    let periodEndDateInput = this.#getInputFromTarget(periodEndDate)

    // reset
    periodStartDateInput.value = ''
    periodEndDateInput.value = ''

    periodStartDateNotAbleToConfirm.value = ''
    periodStartDateNotAbleToConfirm.querySelectorAll('input[type=radio]').forEach(radio => {
      radio.checked = false
    })

    periodEndDateNotAbleToConfirm.value = ''
    periodEndDateNotAbleToConfirm.querySelectorAll('input[type=radio]').forEach(radio => {
      radio.checked = false
    })
    
    if (value == 'Calendar Year'){
      var year = new Date().getFullYear();
      periodStartDateInput.value = `${year}-01-01`
      periodEndDateInput.value = `${year}-12-31`
    }
  }

  clearTimePeriodStartDate(event) {
    let periodStartDate = this['coverageStatusSummaryTimePeriodStartDateInputWrapperTarget']
    if(event.target.checked){
      this.#getInputFromTarget(periodStartDate).value = ''
    }
  }

  clearTimePeriodTerminationDate(event) {
    let periodTerminationDate = this['coverageStatusSummaryTimePeriodEndDateInputWrapperTarget']
    if(event.target.checked) {
      this.#getInputFromTarget(periodTerminationDate).value = ''
    }
  }

  clearPolicyName(event) {
    let planName = this['coverageStatusSummaryPolicyNameInputWrapperTarget']
    if(event.target.checked) {
      this.#getInputFromTarget(planName).value = ''
    }
  }

  uncheckTimePeriodStartDateNotConfirmable(event) {
    let optionsTarget = this['coverageStatusSummaryIsTimePeriodStartDateNotConfirmableInputWrapperTarget']
    if(event.target.value != ''){
      optionsTarget.querySelectorAll('input[type=radio]').forEach(radio => {
        radio.checked = false
      })
    }
  }

  uncheckTimePeriodTerminationDateNotConfirmable(event) {
    let optionsTarget = this['coverageStatusSummaryIsTimePeriodEndDateNotConfirmableInputWrapperTarget']
    if(event.target.value != ''){
      optionsTarget.querySelectorAll('input[type=radio]').forEach(radio => {
        radio.checked = false
      })
    }
  }

  uncheckPolicyNameNotConfirmable(event) {
    let optionsTarget = this['coverageStatusSummaryIsPolicyNameNotConfirmableInputWrapperTarget']
    if(event.target.value != ''){
      optionsTarget.querySelectorAll('input[type=radio]').forEach(radio => {
        radio.checked = false
      })
    }
  }

  #getTargetInputValue = (targetWrapper) => {
    // if radio button is selected
    let selectedRadioButton = targetWrapper.querySelector("input[type=radio]:checked")
    if (selectedRadioButton) return selectedRadioButton.value

    // if boolean
    let checkboxes = targetWrapper.querySelectorAll("input[type=checkbox]")
    if (checkboxes.length == 1) {
      if (!checkboxes[0].checked) return
      
      let targetInput = targetWrapper.children[0]
      if (!targetInput) return
      return targetInput.innerText
    }

    // if checkbox return all checked labels
    let selectedCheckboxes = targetWrapper.querySelectorAll("input[type=checkbox]:checked")
    if (selectedCheckboxes.length > 0) return Array.prototype.slice.call(selectedCheckboxes).map(i=> i.parentElement.innerText)

    let targetInput = this.#getInputFromTarget(targetWrapper)

    // if no input return undefined
    if (!targetInput) return

    // else, return target input value
    return targetInput.value
  }

  #getInputFromTarget = (target) => {
    return target.children[1]
  }

  capitalize(string){
    const base = string.toLowerCase()
    return base.charAt(0).toUpperCase() + base.slice(1)
  }

  allInputWrappers = () => this.#allTargets().filter( item => item.slice(item.length - "InputWrapper".length) == 'InputWrapper' ).map(item=>item)
  
  #allTargets = () => this.constructor.targets.filter(target => this.targetExists(target))

  targetExists(targetName) {
    const target = this.targets.find(targetName)

    if (!target) {
      console.warn(`Target not found: ${targetName}`)
      return false
    }

    return true
  }

  #showNextSection = () => {
    if (
      this.lastUpdatedTargetName === undefined ||
      this.lastUpdatedTargetName === 'internalInformationSummaryTimeInMinutesToCompleteInputWrapper'
    ) return true
    
    return false
  }
}
