import { Controller } from "@hotwired/stimulus";

const MIN_WINDOW_SIZE = "w-[20vw]"

export default class extends Controller {
	static values = {
    title: String,
		subscriberSummaryId: String
  }
	static targets = ["body"]

	connect(){
		this.initChatBox()
	}

	initChatBox(){
		if (this.titleValue){
			this.maximizeWindow()
			this.bodyTarget.classList.remove("hidden")
			
			let callBtn = document.getElementById(`subscriber_summary_${this.subscriberSummaryIdValue}_call_btn`)
			let sectionLength = document.querySelectorAll('div[data-accordion-target="section"]').length - 1
			let headerSectionHeight = sectionLength * 60
			let index = callBtn.dataset.index * 60
			this.element.style.position = 'absolute'
			this.element.style.bottom = `${headerSectionHeight-index}px`
			let callInfo = document.getElementById(`subscriber_summary_${this.subscriberSummaryIdValue}_call_information`)
			if (callInfo != null) { callInfo.classList.remove('hidden') }
			callBtn.classList.add("hidden")
		}
	}

	toggleBodyVisibility() {
		if (this.bodyTarget.classList.contains("hidden")) {
			this.bodyTarget.classList.remove("hidden")
			this.maximizeWindow()
			this.maximizeWindowIcon()
			return 
		}

		this.minimizeWindow()
		this.minimizeWindowIcon()
		this.bodyTarget.classList.add("hidden")
	}

	maximizeWindowIcon() {
		let maximizeWindowIcon = this.element.querySelector('.fa.fa-chevron-up')
		maximizeWindowIcon.classList.remove("fa-chevron-up")
		maximizeWindowIcon.classList.add("fa-chevron-down")
	}

	minimizeWindowIcon() {
		let minimizeWindowIcon = this.element.querySelector('.fa.fa-chevron-down')
		minimizeWindowIcon.classList.remove("fa-chevron-down")
		minimizeWindowIcon.classList.add("fa-chevron-up")
	}

	close(event){
		let confirmed = confirm("Are you sure? All data for this call will be deleted.")

		if(!confirmed){
			this.toggleBodyVisibility();
			event.preventDefault();
		}else{
			let addLink = this.element.nextSibling
			let nestedFieldWrapper = this.element.querySelector('.nested-fields');

			if(nestedFieldWrapper !== null){
				if (nestedFieldWrapper.dataset.newRecord == "true") {
					this.element.remove();
				} else {
					nestedFieldWrapper.querySelector("input[name*='_destroy']").value = 1;
					this.element.style.display = "none";
				}
			}else{
				this.element.remove()
			}
			addLink.classList.remove("hidden")
		}
	}

	maximizeWindow(){
		this.element.classList.remove(MIN_WINDOW_SIZE)
		this.bodyTarget.style.height = '300px'
		this.bodyTarget.style.minHeight = '300px'
		this.element.style.width = `${window.innerWidth - 410}px`
	}

	minimizeWindow(){
		this.element.classList.add(MIN_WINDOW_SIZE)
		this.element.style.width = null
		this.bodyTarget.style.width = null
	}
}


