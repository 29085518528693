export default class AggregateRules {
  constructor({ formState }) {
    this.formState = formState
    this.result = {}
    this.setResult()
    this.setSectionStatus()
  }

  setResult = () => {
    this.result = {
      aggregatePatientResponsibilityAfterDeductibleIsMetSummarySection: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummarySection(),
      aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper(),
      aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCopayAmountInputWrapper: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCopayAmountInputWrapper(),
      aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCoinsuranceAmountInputWrapper: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCoinsuranceAmountInputWrapper(),
      aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountInputWrapper: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountInputWrapper(),
      aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountUnitInputWrapper: this.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountUnitInputWrapper(),
      
      aggregatePatientResponsibilityUpcomingVisitSummarySection: this.aggregatePatientResponsibilityUpcomingVisitSummarySection(),
      aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper: this.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper(),
      aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper: this.aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper(),
      aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper: this.aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper(),
      aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper: this.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper(),
      aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper: this.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper()
    }
  }

  aggregatePatientResponsibilityUpcomingVisitSummarySection = () => true 
  aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper = () => this.aggregatePatientResponsibilityUpcomingVisitSummarySection()
  aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Pay')
  aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Insurance')
  aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')
  aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper = () => this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')

  aggregatePatientResponsibilityAfterDeductibleIsMetSummarySection() {
    return (
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper != '' &&
        !['Co Pay', 'Co Insurance', 'Other'].some(r=> this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.toString().split(',').includes(r))
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay'] &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper != ''
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper == ['Co Insurance'] &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper != ''
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper == ['Co Pay', 'Co Insurance'] &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper != '' &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper != ''
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other') &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper != '' &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper != ''
      )
    )
  }
  aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper = () => this.aggregatePatientResponsibilityAfterDeductibleIsMetSummarySection()
  aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCopayAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Pay')
  aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCoinsuranceAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Insurance')
  aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountInputWrapper = () => this.formState.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')
  aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountUnitInputWrapper = () => this.formState.aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other')

  setSectionStatus = () => {
    if(
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper != '' &&
        !['Co Pay', 'Co Insurance', 'Other'].some(r=> this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.toString().split(',').includes(r))
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Pay') &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper != ''
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Co Insurance') &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper != ''
      ) ||
      (
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper.includes('Other') &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper != '' &&
        this.formState.aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper != ''
      )
    ){
      this.sectionStatus = 'complete'
    }else{
      this.sectionStatus = 'incomplete'
    }
  }
}
