import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static notResponsibilityKindsValue = [
    "No Patient Responsibility - Fully Covered",
    "100% Patient Responsibility Until Deductible Met",
    "100% Patient Responsibility - No Coverage",
    "Deductible",
    "Other"
  ]

  connect() {}

  handleChange(e) {
    let kinds = this.element.querySelectorAll('.checkbox label')
    kinds.forEach(checkbox => {
      if(checkbox.childNodes[0].checked &&
        this.constructor.notResponsibilityKindsValue.includes(checkbox.textContent)){
        this.uncheckAllExcept(e.target.closest('label').textContent)
      }
    });
  }

  uncheckAllExcept(label){
    let kinds = this.element.querySelectorAll('.checkbox label')
    kinds.forEach(checkbox => {
      if(checkbox.textContent != label){
        checkbox.childNodes[0].checked = false
      }
    });
  }
}
