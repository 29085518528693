import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import AggregateRules from './aggregateRules'

export default class extends Controller {
  static values = {
    sectionStatus: String,
    deductibleStatus: Boolean
  }

  static targets = [
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummarySection",
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummaryPatientResponsibilityKindIdsInputWrapper",
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCopayAmountInputWrapper",
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummaryCoinsuranceAmountInputWrapper",
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountInputWrapper",
    "aggregatePatientResponsibilityAfterDeductibleIsMetSummaryOtherAmountUnitInputWrapper",
    "aggregatePatientResponsibilityUpcomingVisitSummarySection",
    "aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapper",
    "aggregatePatientResponsibilityUpcomingVisitSummaryCopayAmountInputWrapper",
    "aggregatePatientResponsibilityUpcomingVisitSummaryCoinsuranceAmountInputWrapper",
    "aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountInputWrapper",
    "aggregatePatientResponsibilityUpcomingVisitSummaryOtherAmountUnitInputWrapper",
  ]

  connect() {
    this.setDataValue()
    this.handleChange()
    this.showHideDeductible()
  }

  handleChange(event) {
    if (event !== undefined) {
      let target = event.currentTarget.closest('div[data-aggregate-summary-target]')
      let targetName = target.getAttribute('data-aggregate-summary-target');
      let dataValue = this.#getTargetInputValue(this[`${targetName}Target`])
      this[`${targetName}Target`].setAttribute('data-value', dataValue);
    }
    this.updateFields()
  }

  updateFields = () => {
    var previousFormState = {}
    var nextFormState = this.#latestFormState()

    while (JSON.stringify(previousFormState) !== JSON.stringify(nextFormState)) {
      let aggregateRules = new AggregateRules({
        formState: nextFormState
      })
      
      let aggregateRulesResult = aggregateRules.result
      this.toggleSectionStatus(aggregateRules.sectionStatus)

      Object.keys(aggregateRulesResult).forEach(key=>{
        let target = this[`${key}Target`]
        let result = aggregateRulesResult[key]
        
        if (result) this.#show(target)
        if (!result) this.#hide(target)
      })

      previousFormState = nextFormState
      nextFormState = this.#latestFormState()
    }
  }

  toggleSectionStatus(status) {
    this.sectionStatusValue = status
    this.dispatch("statusUpdated", { detail: { subscriberId: 'aggregate', status: this.sectionStatusValue } })
  }

  allInputWrappers = () => this.#allTargets().filter( item => item.slice(item.length - "InputWrapper".length) == 'InputWrapper' ).map(item=>item)

  setDataValue() {
    let targets = this.#allTargets()
    targets.forEach(target => {
      let dataValue = this.#getTargetInputValue(this[`${target}Target`])
      this[`${target}Target`].setAttribute('data-value', dataValue);
    })
  }

  showHideDeductible() {
    if(this.deductibleStatusValue) {
      this.hideDeductible()
    }else{
      this.showDeductible()
    }
  }

  hideDeductible() {
    let upcomingVisitTarget = this['aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapperTarget']
    let options = upcomingVisitTarget.querySelectorAll("span.checkbox")
    options.forEach(option => {
      let label = option.querySelector('label').textContent
      if (label == 'Deductible') { option.classList.add('hidden') }
    })
  }

  showDeductible() {
    let upcomingVisitTarget = this['aggregatePatientResponsibilityUpcomingVisitSummaryPatientResponsibilityKindIdsInputWrapperTarget']
    let options = upcomingVisitTarget.querySelectorAll("span.checkbox")
    options.forEach(option => {
      let label = option.querySelector('label').textContent
      if (label == 'Deductible') { option.classList.remove('hidden') }
    })
  }
  
  #allTargets = () => this.constructor.targets

  #latestFormState = () => {
    return this.allInputWrappers().reduce((obj, inputWrapperTargetName)=> {
      obj[inputWrapperTargetName] = this.#getTargetInputDataValue(this[`${inputWrapperTargetName}Target`])
      return obj
    },{})
  }

  #getTargetInputDataValue = (targetWrapper) => {
    return targetWrapper.getAttribute('data-value')
  }

  #getTargetInputValue = (targetWrapper) => {

    // if radio button is selected
    let selectedRadioButton = targetWrapper.querySelector("input[type=radio]:checked")
    if (selectedRadioButton) return selectedRadioButton.value

    // if checkbox return all checked labels
    let selectedCheckboxes = targetWrapper.querySelectorAll("input[type=checkbox]:checked")
    if (selectedCheckboxes.length > 0) return Array.prototype.slice.call(selectedCheckboxes).map(i=> i.parentElement.innerText)

    let targetInput = this.#getInputFromTarget(targetWrapper)

    // if no input return undefined
    if (!targetInput) return

    // else, return target input value
    return targetInput.value
  }

  #getInputFromTarget = (target) => {
    return target.children[1]
  }

  #show = (target) => {
    target.classList.remove("hidden")
    let dataValue = this.#getTargetInputValue(target)
    target.setAttribute('data-value', dataValue)
  }

  #hide = (target) => {
    target.classList.add("hidden")
    target.setAttribute('data-value', '')
  }
}
