import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "inputmask";

export default class extends Controller {
  static values = {
    mask: String,
    placeholder: String,
  };

  MASK_OPTIONS = {
    mask: this.maskValue,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    onBeforeMask: this.beforeMask,
    onUnMask: this.onunmask,
  };

  _connectInput() {
    if (this.placeholderValue !== "") {
      this.MASK_OPTIONS["placeholder"] = this.placeholderValue;
    }
    new Inputmask(this.MASK_OPTIONS).mask(this.element);
  }

  _connectText() {
    const { textContent } = this.element;
    try {
      const formattedText = Inputmask.format(textContent, this.MASK_OPTIONS);
      const isValid = Inputmask.isValid(formattedText, this.MASK_OPTIONS);

      if (isValid) {
        this.element.textContent = formattedText;
      }
    } catch (error) {}
  }

  connect() {
    if (this.element.tagName === "INPUT") {
      this._connectInput();
    } else {
      this._connectText();
    }

    $(document).on("turbo:before-cache", () => {
      const type = this.element.getAttribute("type");
      if (type == "masked_date") {
        Inputmask({"mask": "99/99/9999"}).mask(this.element);
      }
    });
  }

  onunmask(masked, unMasked) {
    const type = this.el.getAttribute("type");
    if (type == "masked_date") {
      return Inputmask.unmask(masked, {
        alias: "datetime",
        inputFormat: "mm/dd/yyyy",
        outputFormat: "yyyy-mm-dd",
      });
    } else {
      return unMasked;
    }
  }

  beforeMask(value, _opts) {
    const type = this.el.getAttribute("type");
    if (type == "masked_date") {
      return Inputmask.unmask(value, {
        alias: "datetime",
        inputFormat: "yyyy-mm-dd",
        outputFormat: "mm/dd/yyyy",
      });
    }
  }
}
