import {Controller} from "@hotwired/stimulus";
import toastr from "toastr";

export default class extends Controller {
  static values = {
    type: String,
    message: String
  };

  connect() {
    toastr[this.typeValue](this.messageValue);
  }
}