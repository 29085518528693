import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "disableOnSubmit" ]
    
  initialize() {
    this.boundDisabler = this.disable.bind(this)
  }
    
  connect() {
    this.controller_mount = this.element
    this.element.addEventListener('submit', this.boundDisabler) 
  } 
    
  disconnect() { 
    this.element.removeEventListener('submit', this.boundDisabler)
  }

  disable() {
    if (this.hasDisableOnSubmitTarget)
      this.disableOnSubmitTargets.forEach(target => {
        target.setAttribute("disabled", true)
        target.classList.add("btn-disabled")
        target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> ${target.innerHTML}`
      })
  }
}