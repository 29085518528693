import { Controller } from "@hotwired/stimulus";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  initialize() {
    this.handleClear = this.handleClear.bind(this);
  }

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener("search", this.handleClear);
  }

  handleClear(e) {
    if (e.target.value.length > 0) return;

    Rails.fire(this.element.closest("form"), "submit");
  }
}
